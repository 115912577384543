<template>
  <div class="row">
    <div class="col">
      <div v-if="$store.getters.logged" class="row mrow">
        <p class="logerr">{{$t('loggato')}}</p>
      </div>
      <div class="row justify-content-center">
        <div class="col">
          <div class="progress col-sm-12 col-md-12 ">
            <div class="progress-bar" role="progressbar" v-bind:style=" 'width: '+step/maxstep*100+'%' " v-bind:aria-valuenow="step/maxstep" aria-valuemin="0" v-bind:aria-valuemax="maxstep">{{step}}</div>
          </div>

          <div class="panel panel-primary" v-if="step==1">
            <div class="panel-heading col-sm-12 col-md-12">
              <h3 class="panel-title">{{$t('ATTENZIONE')}}</h3>
            </div>
            <div class="panel-body">
              <p class="col-sm-12 col-md-12" v-if="this.$i18n.locale=='it'">
                Gentile Utente,
                <br />Prima di iniziare il processo di candidatura, accertati di avere a disposizione il tuo CV in formato PDF:
                ti verrà infatti chiesto di caricarlo nell’ultimo passaggio, dopo i tuoi dati anagrafici.
              </p>
              <p class="col-sm-12 col-md-12" v-else-if="this.$i18n.locale=='en'">
                Dear User,
                <br />Before starting the application process, make sure you have your CV available in PDF format:
                in fact, you will be asked to upload it in the last step, after your personal data.
              </p>
              <div class="col-sm-12 col-md-12" v-if="this.$i18n.locale=='it'">
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="next">{{$t('INIZIA')}}</button>
                <a
                  href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/1t000000PReS/wkclIB1E1cOFSJCT327kteJ13XtPPLW3vDsUauSmtWw"
                  target="_blank"
                >
                  <button class="btn btnBlue float-right" type="button">{{$t('SCARICAMODELLOCV')}}</button>
                </a>
              </div>
              <div class="col-sm-12 col-md-12" v-if="this.$i18n.locale=='en'">
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="next">{{$t('INIZIA')}}</button>
                <a
                  href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/5J000000UJhs/vcWmCsvxwJsjskk84N1Hx5NOJPbMfZLWOJ62Mjy5v2k"
                  target="_blank"
                >
                  <button class="btn btnBlue float-right" type="button">{{$t('SCARICAMODELLOCV')}}</button>
                </a>
              </div>
            </div>
          </div>

          <div class="panel panel-primary" v-if="step==2">
            <div class="panel-heading col-sm-12 col-md-12">
              <h3 class="panel-title">{{$t('DatiPersonali')}}</h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Nome')}}* </label>
                    <input
                      v-model="user.nome"
                      maxlength="100"
                      required
                      type="text"
                      class="form-control"
                      v-bind:class="!user.nome?'is-invalid':'is-valid'"
                      :placeholder="$t('Nome')"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Cognome')}}*</label>
                    <input
                      v-model="user.cognome"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.cognome?'is-invalid':'is-valid'"
                      :placeholder="$t('Cognome')"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <label class="control-label" required style="padding-right:35px;">{{$t('Sesso')}}*</label>
                    <input v-model="user.sesso" class="form-check-input" 
                      v-bind:class="!user.sesso?'is-invalid':'is-valid'"
                      type="radio" name="F" value="F" />
                    <label class="form-check-label" style="padding-right:45px;">F</label>
                    <input v-model="user.sesso" class="form-check-input" 
                      v-bind:class="!user.sesso?'is-invalid':'is-valid'"
                      type="radio" name="M" value="M" />
                    <label class="form-check-label">M</label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Telefono')}}</label>
                    <input
                      v-model="user.telefono" 
                      maxlength="100"
                      required
                      type="tel"
                      class="form-control"
                      v-on:keypress="isNumber($event)"
                      :placeholder="$t('Telefono')"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Cellulare')}}*</label>
                    <input
                      v-model="user.cellulare" 
                      maxlength="100"
                      required
                      type="tel"
                      class="form-control"
                      v-on:keypress="isNumber($event)"
                      v-bind:class="!user.cellulare?'is-invalid':'is-valid'"
                      :placeholder="$t('Cellulare')"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('AltroCellulare')}}</label>
                    <input
                      v-model="user.telefono2" 
                      maxlength="100"
                      required
                      type="tel"
                      class="form-control"
                      v-on:keypress="isNumber($event)"
                      :placeholder="$t('NumeroAlternativo')"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('E-Mail')}}*</label>
                    <input
                      v-model="user.email" 
                      maxlength="100"
                      required
                      type="email"
                      class="form-control"
                      v-bind:class="!user.email || !regEmail.test(user.email)?'is-invalid':'is-valid'"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('MailSecondaria')}}*</label>
                    <input
                      v-model="user.email2" 
                      maxlength="100"
                      required
                      type="email"
                      class="form-control"
                      v-bind:class="!user.email2 || !regEmail.test(user.email2)?'is-invalid':'is-valid'"
                      :placeholder="$t('Email alternativa')"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Conferma Email')}}*</label>
                    <input
                      v-model="user.confermaemail" 
                      maxlength="100"
                      required
                      type="email"
                      class="form-control"
                      v-bind:class="!user.confermaemail || user.confermaemail != user.email?'is-invalid':'is-valid'"
                      placeholder="Email"
                      onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="next" v-bind:disabled="checkPanel1" >{{$t('Successivo')}}</button>
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="prev">{{$t('Indietro')}}</button>
              </div>
            </div>
          </div>

          <div class="panel panel-primary" v-if="step==3">
            <div class="panel-heading col-sm-12 col-md-12">
              <h3 class="panel-title">{{$t('Anagrafica')}}</h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('DatadiNascita')}}*</label>
                    <input 
                      v-model="user.nascita.datanascita" 
                      v-bind:class="!user.nascita.datanascita?'is-invalid':'is-valid'"
                      type="date" required class="form-control" placeholder="dd-mm-yyyy" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <span v-if="$i18n.locale=='it' || user.hacodFisc=='Yes' ">
                    <label class="control-label">{{$t('CodiceFiscale')}}*</label>
                    <input 
                      v-model="user.nascita.codicefiscale" 
                      v-bind:class="!user.nascita.codicefiscale || this.user.nascita.codicefiscale.length != 16?'is-invalid':'is-valid'"
                      maxlength="100" type="text" required class="form-control" placeholder="CF" />
                    </span>
                    <span v-if="$i18n.locale=='en'">
                      <label class="control-label" style="padding-right: 30px;">Do you have an Italian Tax Payer Code?*</label>
                      <input v-model="user.hacodFisc" class="form-check-input" 
                        v-bind:class="!user.hacodFisc?'is-invalid':'is-valid'"
                        type="radio" name="Yes" value="Yes" />
                      <label class="form-check-label" style="padding-right:45px;">Yes</label>
                      <input v-model="user.hacodFisc" class="form-check-input" 
                        v-bind:class="!user.hacodFisc?'is-invalid':'is-valid'"
                        type="radio" name="No" value="No" />
                      <label class="form-check-label">No</label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('CittàdiNascita')}}*</label>
                    <input
                      v-model="user.nascita.cittanascita" 
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.nascita.cittanascita?'is-invalid':'is-valid'"
                      :placeholder="$t('Città')"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"  v-if="$i18n.locale=='it'">
                  <div class="form-group">
                    <label class="control-label">{{$t('ProvinciadiNascita')}}*</label>
                    <select v-model="user.nascita.provincianascita" class="form-control" 
                        v-bind:class="!user.nascita.provincianascita?'is-invalid':'is-valid'" v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Provincia_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('NazionediNascita')}}*</label>
                    <select v-model="user.nascita.nazionenascita" 
                      class="form-control" v-bind:class="!user.nascita.nazionenascita?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Nazione_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('IndirizzoResidenza')}}*</label>
                    <input
                      v-model="user.residenza.indirizzoresidenza"              
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.residenza.indirizzoresidenza?'is-invalid':'is-valid'"
                      :placeholder="$t('Indirizzo Residenza')"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('CittàdiResidenza')}}*</label>
                    <input
                      v-model="user.residenza.cittaresidenza"              
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.residenza.cittaresidenza?'is-invalid':'is-valid'"
                      :placeholder="$t('CittàdiResidenza')"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('CAP')}}*</label>
                    <input
                      v-model="user.residenza.capresidenza"              
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.residenza.capresidenza?'is-invalid':'is-valid'"
                      :placeholder="$t('CAP')"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"  v-if="$i18n.locale=='it'">
                  <div class="form-group">
                    <label class="control-label">{{$t('ProvinciadiResidenza')}}*</label>
                    <select v-model="user.residenza.provinciaresidenza" 
                      class="form-control" v-bind:class="!user.residenza.provinciaresidenza?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Provincia_Picklist__c')" v-bind:key="v" v-bind:value="v">{{v}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('NazionediResidenza')}}*</label>
                    <select v-model="user.residenza.nazioneresidenza" 
                      class="form-control" v-bind:class="!user.residenza.nazioneresidenza?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Nazione_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Cittadinanza')}}*</label>
                    <select v-model="user.residenza.cittadinanza" 
                      class="form-control" v-bind:class="!user.residenza.cittadinanza?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Nazione_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('SecondaCittadinanza')}}</label>
                    <input
                      v-model="user.residenza.cittadinanza2"              
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      :placeholder="$t('Cittadinanza')"
                    />
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-12">
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="next"  v-bind:disabled="checkPanel2" >{{$t('Successivo')}}</button>
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="prev" >{{$t('Indietro')}}</button>
              </div>

            </div>
          </div>

          <div class="panel panel-primary" v-if="step==4">
            <div class="panel-heading col-sm-12 col-md-12">
              <h3 class="panel-title">{{$t('Studi')}}</h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('QualificaoTitolodiStudio')}}*</label>
                    <select v-if="this.$i18n.locale=='it'" v-model="user.studio.titolostudio" class="form-control" 
                      v-bind:class="!user.studio.titolostudio?'is-invalid':'is-valid'" v-on:change="user.studio.nomeistituto = ''; user.studio.istituto = '';"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Account','Titolo_di_studio__c')" v-bind:key="v" v-bind:value="v">{{v}}</option>
                    </select>
                    <select v-if="this.$i18n.locale=='en'" v-model="user.studio.titolostudio" class="form-control" 
                      v-bind:class="!user.studio.titolostudio?'is-invalid':'is-valid'" v-on:change="user.studio.nomeistituto = ''; user.studio.istituto = '';"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option value="">Please select</option>
                      <option value="Diploma di Scuola Superiore">High School Diploma</option>
                      <option value="Laurea triennale">Bachelor's Degree</option>
                      <option value="Laurea magistrale">Master's Degree</option>
                      <option value="Non ho alcun diploma di maturità">I don't have any High School Diploma</option>
                      <option value="Altro (specificare)">Other (please specify)</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label" v-if="user.studio.titolostudio ==  'Diploma di Scuola Superiore'">{{$t('IndirizzodiStudio')}}*</label>
                    <select v-if="user.studio.titolostudio ==  'Diploma di Scuola Superiore' && this.$i18n.locale=='it'" v-model="user.studio.indirizzostudio" class="form-control" 
                      v-bind:class="!user.studio.indirizzostudio?'is-invalid':'is-valid'" v-on:change="user.studio.nomeistituto = ''; user.studio.istituto = '';"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Account','Indirizzo_titolo__c')" v-bind:key="v" v-bind:value="v">{{v}}</option>
                    </select>
                    <select v-if="user.studio.titolostudio ==  'Diploma di Scuola Superiore' && this.$i18n.locale=='en'" v-model="user.studio.indirizzostudio" class="form-control" 
                      v-bind:class="!user.studio.indirizzostudio?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" v-on:change="user.studio.nomeistituto = ''; user.studio.istituto = '';" required>
                      <option value="Istituto Alberghiero">Hotel or Culinary Art High School student</option>
                      <option value="Altro (specificare)">Other (please specify)</option>
                    </select>
                    <div v-if="(user.studio.titolostudio ==  'Laurea triennale' || user.studio.titolostudio ==  'Laurea magistrale')&& this.$i18n.locale=='en'" class="form-check paddingpiu">
                      <label class="control-label">Hotel Management e Culinary Arts Degree*</label> 
                      <br>
                      <div style="margin-left:20px">
                      <input  v-model="user.studio.indirizzostudio" 
                          v-bind:class="'is-valid'"
                          class="form-check-input" type="radio" name="indirizzostudio" value="Hotel Management e Culinary Arts Degree" required />
                        <label class="form-check-label" style="padding-right:45px">{{$t('Si')}}</label>
                        <input  v-model="user.studio.indirizzostudio"
                          v-bind:class="'is-valid'"
                          class="form-check-input" type="radio" name="altrescuole" value="" />
                        <label class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6" v-if="user.studio.indirizzostudio == 'Altro (specificare)' || user.studio.titolostudio == 'Altro (specificare)'">
                  <div class="form-group">
                    <label class="control-label">{{$t('Altro')}}:*</label>
                    <input
                      v-model="user.studio.altroind"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.studio.altroind?'is-invalid':'is-valid'"
                      :placeholder="$t('Altro')"
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="user.studio.titolostudio != 'Non ho alcun diploma di maturità'">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Annoconseguimentoqualifica')}}*</label>
                    <input
                      v-model="user.studio.annotitolo"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-on:keypress="isNumber($event)"
                      v-bind:class="!user.studio.annotitolo || !regNumber.test(user.studio.annotitolo)?'is-invalid':'is-valid'"
                      :placeholder="$t('Annoqualifica')"
                    />
                  </div>
                </div>
                <div v-if="user.studio.titolostudio != 'Non ho alcun diploma di maturità'" class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('NazioneIstituto')}}*</label>
                    <select v-model="user.studio.nazioneistituto" 
                      class="form-control" v-bind:class="!user.studio.nazioneistituto?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Nazione_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" v-if="user.studio.nazioneistituto != 'Italia' && user.studio.titolostudio != 'Non ho alcun diploma di maturità'">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('CittàIstituto')}}*</label>
                    <input
                      v-model="user.studio.cittaistituto"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.studio.cittaistituto?'is-invalid':'is-valid'"
                      :placeholder="$t('CittàIstituto')"
                    />
                  </div>
                </div>
                <div v-if="user.studio.titolostudio != 'Non ho alcun diploma di maturità'" class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('NomeIstituto')}}*</label>
                    <input
                      v-model="user.studio.nomeistituto"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.studio.nomeistituto?'is-invalid':'is-valid'"
                      :placeholder="$t('Istituto')"
                    />
                  </div>
                </div>
              </div>
              <div v-if="user.studio.nazioneistituto == 'Italia'">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{$t('RegioneIstituto')}}*</label>
                    <select v-model="user.studio.regioneistituto" class="form-control" 
                        v-bind:class="!user.studio.regioneistituto?'is-invalid':'is-valid'" v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in lregioni" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                    </div>
                  </div>
                  <div v-if="user.studio.titolostudio != 'Non ho alcun diploma di maturità'" class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{$t('ProvinciaIstituto')}}*</label>
                      <select v-model="user.studio.provinciaistituto" class="form-control" 
                        v-bind:class="!user.studio.provinciaistituto?'is-invalid':'is-valid'" v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in RegioneProv[user.studio.regioneistituto]" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                    </div>
                  </div>
                </div>
                <div v-if="user.studio.titolostudio != 'Non ho alcun diploma di maturità'" class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{$t('CittàIstituto')}}*</label>
                    <select v-model="user.studio.cittaistituto" v-on:change="filtraScuole" class="form-control" 
                        v-bind:class="!user.studio.cittaistituto?'is-invalid':'is-valid'" v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in ProvComune[user.studio.provinciaistituto]" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label class="control-label">{{$t('NomeIstituto')}}*</label>
                      <select v-if="scuoleFilt.length > 0 && user.studio.indirizzostudio ==  'Istituto Alberghiero'" v-model="user.studio.istituto" class="form-control" 
                        v-bind:class="!user.studio.istituto?'is-invalid':'is-valid'" v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in scuoleFilt" v-bind:value="v.Id" v-bind:key="v.Id">{{v.Name}}</option>
                    </select>
                      <input v-if="scuoleFilt.length == 0 || user.studio.indirizzostudio !=  'Istituto Alberghiero'"
                      v-model="user.studio.nomeistituto"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.studio.nomeistituto?'is-invalid':'is-valid'"
                      :placeholder="$t('Istituto')"
                    />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label" style="padding-right:25px;">
                      {{$t('FrequentatoAlma')}}*</label>
                    <br />
                    <div class="form-check paddingpiu">
                      <input  v-model="user.studio.altrescuole" 
                        v-bind:class="!user.studio.altrescuole?'is-invalid':'is-valid'"
                        class="form-check-input" type="radio" name="altrescuole" value="Si" required />
                      <label class="form-check-label" style="padding-right:45px;">{{$t('Si')}}</label>
                      <input  v-model="user.studio.altrescuole"
                        v-bind:class="!user.studio.altrescuole?'is-invalid':'is-valid'"
                        class="form-check-input" type="radio" name="altrescuole" value="No" />
                      <label class="form-check-label">No</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6" v-if="user.studio.altrescuole=='Si'">
                  <div class="form-group">
                    <label class="control-label">{{$t('AltraScuolaCucina')}}*</label>
                    <input
                      v-model="user.studio.altrascuolacucina"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.studio.altrascuolacucina?'is-invalid':'is-valid'"
                      :placeholder="$t('AltraScuolaCucina')"
                    />
                  </div>
                </div>
                
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('ConosciutoAlma')}}*</label>
                    <select v-if="this.$i18n.locale=='it'" v-model="user.studio.conosciutoalma" class="form-control" 
                      v-bind:class="!user.studio.conosciutoalma?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Account','ConosciutoALMA__c')" v-bind:key="v" v-bind:value="v">{{v}}</option>
                    </select>
                    <select v-if="this.$i18n.locale=='en'" v-model="user.studio.conosciutoalma" class="form-control" 
                      v-bind:class="!user.studio.conosciutoalma?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option value="ALMA ha fatto orientamento presso la mia scuola">ALMA visited the school I am attending</option>
                      <option value="L'ho conosciuta a una fiera">I visited ALMA's booth at an educational fair/virtual fair</option>
                      <option value="I visited ALMA's stand at an educational fair">I visited ALMA's stand at an educational fair</option>
                      <option value="L'ho trovata con una ricerca su internet">I found it with a search on the internet</option>
                      <option value="Seguo ALMA sui social">I follow ALMA on social networks</option>
                      <option value="Me l'ha consigliata uno chef">A chef recommended it to me</option>
                      <option value="Me l'ha consigliata un amico che ha frequentato ALMA">A friend who attended ALMA recommented it to me</option>
                      <option value="L'ho visto in TV">I saw it on TV</option>
                      <option value="Ho partecipato ad un corso ALMA in passato">I have participated in an ALMA course in the past</option>
                      <option value="Frequento una scuola internazionale partner di ALMA">ALMA is a partner of the school I am attending</option>
                      <option value="Sono in contatto con un Consulente per lo Studio all'estero">I was in contact with an Education Abroad Consultant</option>
                      <option value="Altro">Other (please specify)</option>  
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6" v-if="user.studio.conosciutoalma == 'Altro'">
                  <div class="form-group">
                    <label class="control-label">{{$t('Altro')}}:*</label>
                    <input
                      v-model="user.studio.altroCA"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.studio.altroCA?'is-invalid':'is-valid'"
                      :placeholder="$t('Altro')"
                    />
                  </div>
                </div>
              </div>
              <div v-if="this.$i18n.locale=='en' && enableMothertongue" class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">I declare that my level of knowledge of English language is*:</label>
                    <select v-model="user.studio.levelOfEnglish" class="form-control" 
                      v-bind:class="!user.studio.levelOfEnglish?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option value="Madrelingua">mothertongue</option>
                      <option value="A1/A2">A1/A2</option>
                      <option value="B1/B2">B1/B2</option>
                      <option value="C1/C2">C1/C2</option>
                    </select>
                    <p>* According to the Common European Framework of Reference for Languages</p>
                  </div>
                </div>
                
                <div class="col-sm-12 col-md-6">
                  <div v-if="user.studio.levelOfEnglish && user.studio.levelOfEnglish != 'Madrelingua'" class="form-group">
                    <label class="control-label">If you have an English language certificate, please upload it here.</label>
                    <input type="file" id="file" ref="file" accept=".pdf,.docx,.doc" v-on:change="fileUploadCertificate"
                      name="englishCertificate" style="padding: 3px" class="form-control"/>
                    <p>{{$t('FilecaricareLabel')}}</p>
                    <div v-if="fileDataCertificate">
                      <label>{{$t('Dimensioni')}}</label> {{certificateMB}} MB<br/>
                      <div v-if="erroredimensioniCertificate" class="alert alert-danger" role="alert">{{$t('DimensioniEccessive')}}</div><br/>                  
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12">
                  <p>Please consider that you may be subject to an English language test at the discretion of the Enrolment Department</p>
                </div>
              </div>    
              <div class="col-sm-12 col-md-12">
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="next" v-bind:disabled="checkPanel3">{{$t('Successivo')}}</button>
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="prev">{{$t('Indietro')}}</button>
              </div>

            </div>
          </div>

          <div class="panel panel-primary" v-if="step==5">
            <div class="panel-heading col-sm-11 col-md-12">
              <h3 class="panel-title">{{$t('InserireCurriculum')}}</h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-11 col-md-12">
                  <div class="form-group">
                    <label>{{$t('CaricaCurriculum')}}*</label>
                    <input type="file" id="file" ref="file" accept=".pdf,.docx,.doc" v-on:change="fileUpload" name="curriculum" required 
                    style="padding: 3px"
                      class="form-control" v-bind:class="!fileData || erroredimensioni?'is-invalid':'is-valid'"
                      />
                      <br/>
                    <label for="curriculum">
                        {{$t('FilecaricareLabel')}}
                    </label>
                    <div v-if="fileData">
                      <label >{{$t('Dimensioni')}}</label> {{fileMB}} MB<br/>
                      <div v-if="erroredimensioni" class="alert alert-danger" role="alert">{{$t('DimensioniEccessive')}}</div><br/>                  
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-12">
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="next"  v-bind:disabled="checkPanel4" >{{$t('Successivo')}}</button>
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="prev" >{{$t('Indietro')}}</button>
              </div>

            </div>
          </div>
          
          <div class="panel panel-primary" v-if="step==6">
            <div class="panel-heading col-sm-12 col-md-12">
              <h3 class="panel-title">{{$t('Documento di riconoscimento')}}</h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('TipoDocumento')}}*</label>
                    <select v-if="this.$i18n.locale=='it'" v-model="user.documentoRic.tipoDoc" 
                      class="form-control" v-bind:class="!user.documentoRic.tipoDoc?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Account','Tipo_Documento__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                    <select v-if="this.$i18n.locale=='en'" v-model="user.documentoRic.tipoDoc"
                      class="form-control" v-bind:class="!user.documentoRic.tipoDoc?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option value="">Please select</option>
                      <option value="Carta d'identità">Identity card</option>
                      <option value="Passaporto">Passport</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Numero')}}*</label>
                    <input
                      v-model="user.documentoRic.numero"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.documentoRic.numero?'is-invalid':'is-valid'"
                      :placeholder="$t('Numero')"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('DataRilascio')}}*</label>
                    <input
                      v-model="user.documentoRic.dataRil" 
                      v-bind:class="!user.documentoRic.dataRil?'is-invalid':'is-valid'"
                      type="date" required class="form-control"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('DataScadenza')}}*</label>
                    <input
                      v-model="user.documentoRic.dataScad" 
                      v-bind:class="!user.documentoRic.dataScad?'is-invalid':'is-valid'"
                      type="date" required class="form-control"
                    />
                  </div>
                </div>
              </div>

              <!-- RICHIESTA STATO RILASCIO DOCUMENTO -->
              <div class=row>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('NazioneRilascio')}}*</label>
                    <select v-model="user.documentoRic.statoRilascio.Name" 
                      class="form-control" v-bind:class="!user.documentoRic.statoRilascio.Name?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Nazione_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                      <option value="Italia" selected>Italia</option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- RICHIESTA PROVINCIA E COMUNE -->
              <div class="row" v-if="user.documentoRic.statoRilascio.Name == 'Italia'">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">Provincia del comune di rilascio*</label>
                    <select v-model="user.documentoRic.provinciaRilascio" 
                      class="form-control" v-bind:class="!user.documentoRic.provinciaRilascio?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Provincia_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">Comune di rilascio*</label>
                    <select v-model="user.documentoRic.comuneRilascio.Name" 
                      class="form-control" v-bind:class="!user.documentoRic.comuneRilascio.Name?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required :disabled="!user.documentoRic.provinciaRilascio">
                      <option value="comuneNotFound">Comune non trovato</option>
                      <option v-for="v in ProvComune[user.documentoRic.provinciaRilascio]" v-bind:key="v" v-bind:value="v" >{{$t(v)}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- RICHIESTA NOME COMUNE SE NON TROVATO IN ELENCO -->
              <div v-if="user.documentoRic.comuneRilascio.Name == 'comuneNotFound'" class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">Inserisci il comune manualmente</label>
                    <input
                      v-model="user.documentoRic.ente"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      v-bind:class="!user.documentoRic.ente?'is-invalid':'is-valid'"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-11 col-md-12">
                  <div class="form-group">
                    <label>{{$t('CaricaDocumentoIdentita')}}*</label>
                    <input type="file" id="file" ref="file" accept=".pdf,.jpeg" v-on:change="fileUploadDoc" name="docId" required 
                    style="padding: 3px"
                      class="form-control" v-bind:class="!fileDataDoc || erroredimensioniDoc?'is-invalid':'is-valid'"
                      />
                      <br/>
                    <label for="docId">
                        {{$t('FilecaricareDocLabel')}}
                    </label>
                    <div v-if="fileDataDoc">
                      <label >{{$t('Dimensioni')}}</label> {{fileDocMB}} MB<br/>
                      <div v-if="erroredimensioniDoc" class="alert alert-danger" role="alert">{{$t('DimensioniEccessive')}}</div><br/>                  
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="next"  v-bind:disabled="checkPanelDoc" >{{$t('Successivo')}}</button>
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="prev" >{{$t('Indietro')}}</button>
              </div>
            </div>
          </div>

          <!--div class="panel panel-primary" v-if="step==7">
            <div class="panel-heading col-sm-12 col-md-12">
              <h3 class="panel-title">{{$t('DatidiFatturazione')}}</h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('RagioneSociale')}}</label>
                    <input
                      v-model="user.fatturazione.ragionesociale"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      :placeholder="$t('RagioneSociale')"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('PartitaIVA')}}</label>
                    <input
                      v-model="user.fatturazione.piva"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      :placeholder="$t('P.IVA')"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('CodiceFiscale')}}</label>
                    <input
                      v-model="user.fatturazione.codicefiscale"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      :placeholder="$t('CodiceFiscale')"
                    />
                    <span v-if="$i18n.locale=='en'"><i>If you do not have an Italian Tax Payer Code, please fill in the field with your surname</i></span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">Email Pec</label>
                    <input
                      v-model="user.fatturazione.pec"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      placeholder="Email Pec"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('CodiceSDI')}}</label>
                    <input
                      v-model="user.fatturazione.sdi"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      :placeholder="$t('FatturazioneElettronica')"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Indirizzo')}}</label>
                    <input
                      v-model="user.fatturazione.indirizzo"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      :placeholder="$t('Indirizzo')"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Città')}}</label>
                    <input
                      v-model="user.fatturazione.citta"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      :placeholder="$t('Citta')"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('CAP')}}</label>
                    <input
                      v-model="user.fatturazione.cap"
                      maxlength="100"
                      type="text"
                      required
                      class="form-control"
                      :placeholder="$t('CAP')"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6" v-if="$i18n.locale=='it'">
                  <div class="form-group">
                    <label class="control-label">{{$t('Provincia')}}</label>
                    <select 
                      v-model="user.fatturazione.provincia"              
                      class="form-control" :title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Provincia_Picklist__c')" v-bind:key="v" v-bind:value="v">{{v}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">{{$t('Paese')}}</label>
                    <select v-model="user.fatturazione.paese" 
                      class="form-control" v-bind:class="!user.fatturazione.paese?'is-invalid':'is-valid'"
                      v-bind:title="$t('Scegliunaopzione')" required>
                      <option v-for="v in getPicklistValues('Lead','Nazione_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-12">
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="next"  v-bind:disabled="checkPanel5" >{{$t('Successivo')}}</button>
                <button class="btn btnBlue float-right" type="button" v-on:click.prevent="prev" >{{$t('Indietro')}}</button>
              </div>

            </div>
          </div-->

          <div class="panel panel-primary" v-if="step==7">
            <div class="panel-heading col-sm-12 col-md-12">
              <h3 class="panel-title">{{$t('Letuecredenziali')}}</h3>
            </div>
            <div class="panel-body">
              <div class="row" style="text-align: center; margin: 5px">
                <div class="col-sm-12 col-md-12">
                  <mark>{{$t('TestoRegistrazione')}}</mark>
                </div>
              </div>
              <div class="row" v-if="!this.$store.state.userprofile.Id">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">USERNAME</label>
                    <input 
                      v-bind:disabled="this.$store.state.userprofile.Id"
                      v-model="user.username"
                      v-bind:class="!user.username?'is-invalid':'is-valid'"
                      maxlength="100" type="text" class="form-control" placeholder="Username" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group ">
                    <label class="control-label">PASSWORD</label>
                    <div class="input-group">
                      <input
                        v-model="user.password"
                        maxlength="100"
                        v-bind:type="reveal?'text':'password'"
                        class="form-control"
                        v-bind:class="!user.password?'is-invalid':'is-valid'"
                        placeholder="Password"
                      />
                      <div class="input-group-append" v-on:click="chgReveal">
                        <span class="input-group-text">
                          <span v-if="!reveal" class="oi oi-eye"></span>
                          <span v-if="reveal" class="oi oi-code"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group ">
                    <label class="control-label">{{$t('ConfermaPassword')}}</label>
                    <div class="input-group">
                      <input
                        v-model="user.pwd2"
                        maxlength="100"
                        v-bind:type="reveal?'text':'password'"
                        class="form-control"
                        v-bind:class="!user.pwd2 || user.password != user.pwd2?'is-invalid':'is-valid'"
                        placeholder="Password"
                      />
                      <div class="input-group-append" v-on:click="chgReveal">
                        <span class="input-group-text">
                          <span v-if="!reveal" class="oi oi-eye"></span>
                          <span v-if="reveal" class="oi oi-code"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">USERNAME</label>
                    <input disabled v-model="this.$store.state.userprofile.Username__pc" 
                      maxlength="100" type="text" class="form-control" placeholder="Username" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <h4>{{$t('ModificaPasswordLabel')}}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group paddingpiu custom-checkbox">
                    <input 
                      v-model="user.privacy"
                      type="checkbox" class="custom-control-input" id="privacy_acceptance">
                    <label class="custom-control-label" for="privacy_acceptance" v-if="this.$i18n.locale=='it'">
                      <span style=" font-weight: bold;margin: 8px; color: red;">*</span>Ho letto e accetto
                      <a
                        href="https://www.alma.scuolacucina.it/privacy-policy/"
                        target="blank_"
                      >
                        <b>Informativa sulla Privacy</b>
                      </a>
                    </label>
                    <label class="custom-control-label" for="privacy_acceptance" v-else>
                      <span style=" font-weight: bold;margin: 8px; color: red;">*</span>I have read and accepted
                      <a
                        href="https://www.alma.scuolacucina.it/privacy-policy/"
                        target="blank_"
                      >
                        <b>Privacy Policy</b>
                      </a>
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            <div class="row">
              <div>
                <div class="form-group paddingpiu custom-checkbox">
                  <label class="control-label" style="padding-right:25px;" v-if="this.$i18n.locale=='it'">
                    <span style=" font-weight: bold;margin: 8px; color: red;">*</span>Per finalità di comunicazione promozionale e/o istituzionale e l’utilizzo di immagini e video, inerenti alla Scuola di Cucina, eventi, lezioni nel sito Web ALMA, all’interno di Facebook e di YouTube, e di tutti gli altri strumenti multimediali adatti a rappresentare l’immagine della Scuola. <br>
                                      Per finalità di marketing quali invio di informazioni su iniziative della  Scuola  (es.  newsletter,  comunicazioni  promozionali,  corsi,  open  day, eventi, servizi, ecc.) e/o dei nostri partner, che potrebbero essere di interesse dell’utente, anche tramite modalità automatizzate (es. e-mail, sms)</label>
                  <label class="control-label" style="padding-right:25px;" v-else>
                    <span style=" font-weight: bold;margin: 8px; color: red;">*</span>For promotional and / or institutional communication purposes and the use of images and videos, relating to the Cooking School, events, lessons on the ALMA website, within Facebook and YouTube, and all other multimedia tools suitable for represent the image of the School.<br>
                    For marketing purposes such as sending information on School initiatives (eg newsletters, promotional communications, courses, open days, events, services, etc.) and / or our partners, which may be of interest to the user, also through automated methods (e.g. e-mail, sms)</label>
                  <div style="margin-left:18px;">
                    <input for="marketing_acceptance" class="form-check-input" type="radio" name="true" v-bind:value="true"  v-model="user.marketing"/>
                    <label class="form-check-label" style="padding-right:45px;">{{$t('Si')}}</label>
                    <input for="marketing_acceptance" class="form-check-input" type="radio" name="false" v-bind:value="false"  v-model="user.marketing"/>
                    <label class="form-check-label">No</label>
                  </div> 
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
            </div>

            <div class="col-sm-12 col-md-12">
              <button v-if="uploading" class="btn btn-primary float-right" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </button> 
              <button v-if="uploading == false" v-bind:disabled="checkPanel6" class="btn btnBlue float-right" type="button" v-on:click.prevent="manda" >{{$t('Fine')}}</button>
              <button class="btn btnBlue float-right" type="button" v-on:click.prevent="prev" >{{$t('Indietro')}}</button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import apiclient from '@/apiclient.js'

export default {
  name: "formcandidatura",
  props: { 
    productcode:String
  },
  created: function() {
    console.log('installa evento');
    window.addEventListener('beforeunload', this.chiusura);
    this.$store.commit('SET_ROUTERLOCK', true);
    //var userData=localStorage.getItem('candidatura');
    /*if (userData) {
      //console.log('recovering...');
      //this.$set(this,'user',JSON.parse(userData));
    }
    if (!this.$store.state.userprofile)
      this.$store.commit('GET_USER_DATA');

    var userData2=this.$store.state.userprofile;
    if (userData2) {
      this.getuserdata();
    }*/
  },
  mounted: function() {
    window.addEventListener("beforeunload", this.handleBeforeUnload)

    var prod=this.$route.params.productcode;
    console.log('prod',prod);
    this.corso= prod.split('-')[0];
    this.edizione= prod.split('-')[1];

    console.log('this.corso',this.corso);
    console.log('this.edizione',this.edizione);
    this.$store.state.productcode = prod;
    this.$store.state.course = this.corso;
    this.$store.state.edition = this.edizione;
    var self = this;
//    this.$store.dispatch('getProdotti', codiceprodotto);
    this.$store.dispatch('getCampi', ['Lead','Account','Opportunity'])
    .then(function() {
      console.log('get campi finito')
      //console.log(resp)
    });
    this.initializeRegProv();
    this.$store.dispatch('getScuole')
    .then(function(resp){
      console.log('get scuole finito');
      if(resp.length > 0) self.scuole = resp;
      self.initializeRegProv();
    });
    // eslint-disable-next-line no-undef
    gtag('event', 'add_to_cart', { 'items': [this.$route.params.productcode, this.corso, this.edizione], 'send_to': 'GTM-KGMX463' });      

  },
  data: function() {
    return {
      regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regNumber:/^[0-9]+$/,
      myfile: {},
      myCertificate: {},
      myfileDoc: {},
      fileData: '',
      fileDataDoc: '',
      fileDataCertificate: '',
      bloccochiusura: true,
      step: 1,
      maxstep:7,
      reveal: false,
      uploading: false,
      scuole:[],
      scuoleFilt:[],
      lregioni:[],

      fileMB: 0,
      fileDocMB: 0,
      certificateMB: 0,

      user: {
        nome:'',
        cognome:'',
        sesso:'',
        telefono:'',
        cellulare:'',
        telefono2:'',
        email:'',
        confermaemail: '',
        email2:'',

        hacodFisc: '',
        nascita: {
          datanascita:'',
          codicefiscale:'',
          cittanascita:'',
          provincianascita:'',
          nazionenascita:'',
        },
        RegioneProv: new Map(),
        ProvComune: new Map(),
        residenza: {
          indirizzoresidenza:'',
          cittaresidenza:'',
          capresidenza:'',
          provinciaresidenza:'',
          nazioneresidenza:'',
          cittadinanza:'',
          cittadinanza2:'',
        },

        studio: {
          titolostudio:'',
          indirizzostudio:'',
          altroind:'',
          annotitolo:'',
          nomeistituto:'',
          istituto:'',
          cittaistituto:'',
          provinciaistituto: '',
          regioneistituto:'',
          nazioneistituto:'',
          altrescuole:'',
          conosciutoalma:'',
          altroCA: '',
          altrascuolacucina: '',
          levelOfEnglish: '',
          hasCertificate: ''
        },

        filecv:'',

        fatturazione: {
          ragionesociale:'',
          piva:'',
          codicefiscale:'',
          pec:'',
          sdi:'',
          indirizzo:'',
          citta:'',
          cap:'',
          provincia:'',
          paese:''
        },
        documentoRic: {
          tipoDoc: '',
          numero:'',
          dataRil: '',
          dataScad: '',
          ente: '',
          comuneRilascio: {
            Id: '',
            Name: ''
          },
          provinciaRilascio: '',
          statoRilascio:  {
            Id: '',
            Name: ''
          }
        },
        username:'',
        password:'',
        pwd2:'',
        privacy: false,
        marketing: true

      }
    };
  },
 computed: {
   erroredimensioni: function() {
    if (!this.myfile) return false;
    // if (this.myfile.size<=5242880) return false;
    if (this.myfile.size<=4500000) return false;
    return true;
   },
   erroredimensioniDoc: function() {
    if (!this.myfileDoc) return false;
    // if (this.myfileDoc.size<=5242880) return false;
    if (this.myfileDoc.size<=4500000) return false;
    return true;
   },
   erroredimensioniCertificate: function() {
    if (!this.myCertificate) return false;
    // if (this.myCertificate.size<=5242880) return false;
    if (this.myCertificate.size<=4500000) return false;
    return true;
   },
    conosciutoalma: function() {
      if (this.$store.state.campi) {
        if (this.$store.state.campi.has('Account')) {
          console.log('oggetto')
          var af=this.$store.state.campi.get('Account')
          if (af.has('ConosciutoALMA__c')) {
            console.log('campo')
            var afv=af.get('ConosciutoALMA__c');
            console.log(afv)
            if (afv) return afv.values.split(';');
          }
        }
      }
      return [];
    },
    checkPanel1: function() {
      
      if (!this.user.nome) return true;
      if (!this.user.cognome) return true;
      if (!this.user.sesso) return true;
      if (!this.user.cellulare) return true;
      if (!this.user.email) return true;
      if (!this.user.confermaemail || this.user.confermaemail != this.user.email) return true;
      if (!this.user.email2) return true;
      
      return false;
    },
    checkPanel2: function() {
      
      if (!this.user.nascita.datanascita) return true;
      if (!this.user.hacodFisc && this.$i18n.locale === 'en') return true;
      if (this.user.hacodFisc == '' && this.user.nascita.codicefiscale == '') return true;
      if (this.user.nascita.codicefiscale != '' && this.user.nascita.codicefiscale.length != 16) return true;
      if (this.user.nascita.codicefiscale == '' && this.user.hacodFisc == 'Yes') return true;
      // if (this.user.nascita.codicefiscale && this.user.nascita.codicefiscale.length != 16) return true;
      // if (!this.user.nascita.codicefiscale && this.user.hacodFisc == 'Yes') return true;
      if (!this.user.nascita.cittanascita) return true;
      if (!this.user.nascita.provincianascita && this.$i18n.locale === 'it') return true;
      if (!this.user.nascita.nazionenascita) return true;
      if (!this.user.residenza.indirizzoresidenza) return true;
      if (!this.user.residenza.cittaresidenza) return true;
      if (!this.user.residenza.capresidenza) return true;
      if (!this.user.residenza.provinciaresidenza && this.$i18n.locale === 'it') return true;
      if (!this.user.residenza.nazioneresidenza) return true;
      if (!this.user.residenza.cittadinanza) return true;
      
      return false;
    },
    checkPanel3: function() {
      
      if (!this.user.studio.titolostudio) return true;
      if (this.user.studio.titolostudio == 'Diploma di Scuola Superiore' && !this.user.studio.indirizzostudio) return true;
      if ((this.user.studio.indirizzostudio == 'Altro (specificare)' || this.user.studio.titolostudio == 'Altro (specificare)') && !this.user.studio.altroind) return true;
      if(this.user.studio.titolostudio != 'Non ho alcun diploma di maturità') {
        if (!this.user.studio.annotitolo) return true;
        if (!this.user.studio.nomeistituto && !this.user.studio.istituto) return true;
        if (!this.user.studio.cittaistituto) return true;
        if (!this.user.studio.provinciaistituto && this.user.studio.nazioneistituto === 'Italia') return true;
        if (!this.user.studio.nazioneistituto) return true;
      }
      if(this.enableMothertongue && this.$i18n.locale === 'en') {
        if (!this.user.studio.levelOfEnglish) return true;
      }
      if (!this.user.studio.altrescuole) return true;
      if (this.user.studio.altrescuole=='Si' && !this.user.studio.altrascuolacucina) return true;
      if (!this.user.studio.conosciutoalma) return true;   
      if ((this.user.studio.conosciutoalma == 'Altro' || this.user.studio.conosciutoalma == 'More') && !this.user.studio.altroCA) return true;   
      
      return false;
    },
    checkPanel4: function() {
      
      if (!this.fileData) return true;
      if (this.erroredimensioni) return true;
      
      return false;
    },
    checkPanel5: function() {
      
      if (!this.user.studio.titolostudio) return true;
      
      return false;
    },
    checkPanel6: function() {
      if (!this.user.username) return true;
      if (!this.user.password) return true;
      if(!this.$store.state.userprofile.Id){
        if (!this.user.pwd2) return true;
        if (this.user.pwd2 !== this.user.password) return true;
      }
      if (!this.user.privacy) return true;
      if (!this.user.marketing) return true;
      return false;
    },
    checkPanelDoc: function() {
      
      if (!this.user.documentoRic.tipoDoc) return true;
      if (!this.user.documentoRic.dataRil) return true;
      if (!this.user.documentoRic.dataScad) return true;
      if (!this.user.documentoRic.numero) return true;
      if (this.user.documentoRic.statoRilascio.Name == '') return true;
      if (this.user.documentoRic.statoRilascio.Name == 'Italia' && this.user.documentoRic.comuneRilascio.Name == '') return true;
      if (this.user.documentoRic.statoRilascio.Name == 'Italia' 
        && this.user.documentoRic.comuneRilascio.Name == 'comuneNotFound' 
        && !this.user.documentoRic.ente) return true;
      if (!this.myfileDoc) return true;
      if (this.erroredimensioniDoc) return true;
      
      return false;
    },
    enableMothertongue: function() { // a true abilita la richiesta del livello di inglese
      return true 
    },
    enableIdPerICP: function() { // a true abilita la richiesta del documento d'identità per i corsi ICP
      return true
    }

 },
  methods: {
    filtraScuole: function(){
      console.log('filtro');
      console.log(this.user.studio.cittaistituto.toLowerCase());
      console.log(this.user.studio.provinciaistituto.toLowerCase());
      console.log(this.user.studio.regioneistituto.toLowerCase());
      var self = this;
      this.scuoleFilt=[];
      this.user.studio.istituto='';
      this.user.studio.nomeistituto='';

      this.scuole.forEach(function(s){

        console.log(s.BillingCity.toLowerCase(), self.user.studio.cittaistituto.toLowerCase()) 
        console.log(s.BillingState.toLowerCase(), self.user.studio.provinciaistituto.toLowerCase()) 
        console.log(s.Regione_Istituto__c.toLowerCase(), self.user.studio.regioneistituto.toLowerCase())

        if(s.BillingCity.toLowerCase() == self.user.studio.cittaistituto.toLowerCase() 
            && s.BillingState.toLowerCase() == self.user.studio.provinciaistituto.toLowerCase() 
            && s.Regione_Istituto__c.toLowerCase() == self.user.studio.regioneistituto.toLowerCase()){
              console.log('match!!');
              self.scuoleFilt.push(s);
            }
      })

      console.log(this.scuoleFilt);
    },
    initializeRegProv: function(){
      var self = this;
      this.$store.dispatch('getRegioniProvincie', 'regione').then(function(result){
        self.RegioneProv = result;
        self.lregioni  = Object.keys(self.RegioneProv);
        console.log('self.RegioneProv',self.RegioneProv);
      });
      this.$store.dispatch('getRegioniProvincie', 'provincia').then(function(result){
        self.ProvComune = result;
        console.log('self.ProvComune',self.ProvComune);
      });
    },
    isNumber(e) {
        let char = String.fromCharCode(e.keyCode);
        if (/^[+][0-9]+|^[0-9]+/.test(char)) return true;
        else e.preventDefault();
    },
    chgReveal: function(){
      this.reveal = !this.reveal;
    },
    fileUpload: function() {
      console.log('--> fileUpload')
      if (this.$refs.file.files.length>0) {
        this.myfile=this.$refs.file.files[0];
        const reader = new FileReader();
        reader.onload = this.doReadFile
        reader.readAsDataURL(this.myfile);
      }
      console.log('this.myfile', this.myfile)
    },
    fileUploadDoc: function() {
      console.log('--> fileUploadDoc')
      if (this.$refs.file.files.length>0) {
        this.myfileDoc=this.$refs.file.files[0];
        const reader = new FileReader();
        reader.onload = this.doReadFileDoc
        reader.readAsDataURL(this.myfileDoc);
      }
      console.log('this.myfileDoc', this.myfileDoc)
    },
    fileUploadCertificate: function() {
      if (this.$refs.file.files.length>0) {
        this.myCertificate=this.$refs.file.files[0];
        console.log('this.myCertificate',this.myCertificate)
        const reader = new FileReader();
        reader.onload = this.doReadFileCertificate
        reader.readAsDataURL(this.myCertificate);
      }
    },
    doReadFile: function (e) {
      console.log('doReadFile');
      console.log(e);
      this.fileMB = this.myfile.size / 1000000
      this.fileData=e.target.result.split(',')[1];
    },
    doReadFileDoc: function (e) {
      console.log('doReadFileDoc');
      console.log(e);
      this.fileDocMB = this.myfileDoc.size / 1000000
      this.fileDataDoc=e.target.result.split(',')[1];
    },
    doReadFileCertificate: function (e) {
      console.log('doReadFile');
      console.log(e);
      this.certificateMB = this.myCertificate.size / 1000000
      this.fileDataCertificate=e.target.result.split(',')[1];
      console.log('this.fileDataCertificate',this.fileDataCertificate)
    },
    getPicklistValues: function(o,f) {
      if (this.$store.state.campi) {
        if (this.$store.state.campi.has(o)) {
          console.log('oggetto', o)
          var af=this.$store.state.campi.get(o)
          if (af.has(f)) {
            console.log('campo', f)
            var afv=af.get(f);
            // console.log('afv',afv)
            if (afv)return afv.values.split(';');
          }
        }
      }
      return [];
    },
    chiusura: function(event) {
      console.log('blocco chiusura');
      if (this.bloccochiusura) {
        event.preventDefault();
        if (confirm('Si desidera uscire?')) {
          event.returnValue='';
          this.$store.commit('SET_ROUTERLOCK', false);
        } else {
          event.returnValue=true;
        }
      }
    },
    manda: function() {
      if (confirm('Confermi e manda?')) {
        this.uploading = true;
        this.setuserdata();
        // eslint-disable-next-line no-undef
      }
    },
    next: function() {
      if (this.checkvalidstep(this.step) == false) {
        console.log('----- ALERT: candidatura errata -----')
        return
      }

      if (this.step==2) this.user.username=this.user.email; // set username = email
      if (this.step == 6) {
        var comuneName = this.user.documentoRic.comuneRilascio.Name
        if(comuneName == 'comuneNotFound') comuneName = ''
        this.$store.dispatch('getIdComuneStatoRilascio', {
          comuneName: comuneName, 
          statoName: this.user.documentoRic.statoRilascio.Name,
          provinciaName: this.user.documentoRic.provinciaRilascio
        }).then(resp => {
          console.log(resp)
          this.user.documentoRic.statoRilascio.Id = resp[this.user.documentoRic.statoRilascio.Name]
          this.user.documentoRic.comuneRilascio.Id = resp[this.user.documentoRic.comuneRilascio.Name]
        });
      }

      localStorage.setItem('candidatura', JSON.stringify(this.user)); 
      this.step++;
      // if(this.$i18n.locale === 'en' && this.step === 6) {
      //   if(!this.corso.includes('ICP') || !this.enableIdPerICP) this.step++; //salta documento
      // }

      if (this.step == 7 && this.$store.state.userprofile.Id) {
        this.user.username = this.$store.state.userprofile.Username__pc
        this.user.password = this.$store.state.userprofile.Password__pc
        console.log('this.user.username',this.user.username)
        console.log('this.user.password',this.user.password)
        this.vuoiUscire = true
      }
      window.scrollTo(0,0);

      // eslint-disable-next-line no-undef
      gtag('event', 'screen_view', { 'screen_name': '/candidatura/'+this.step, 'send_to': 'GTM-KGMX463' });      
    },
    prev: function() {
      this.step--;
      if(this.$i18n.locale === 'en' && this.step === 6) {
        if(!this.corso.includes('ICP') || !this.enableIdPerICP) this.step--;
      }
      //if (this.step==6) this.step--; // salta fatturazione
      this.uploading=false;
      window.scrollTo(0,0);      

      // eslint-disable-next-line no-undef
      gtag('event', 'screen_view', { 'screen_name': '/candidatura/'+this.step, 'send_to': 'GTM-KGMX463' });      
    },
    checkvalidstep: function(step) {
      var pass = true

      switch(step) {
        case 2:
          
          if (!this.user.nome)      { console.log('manca this.user.nome');      pass = false }
          if (!this.user.cognome)   { console.log('manca this.user.cognome');   pass = false }
          if (!this.user.sesso)     { console.log('manca this.user.sesso');     pass = false }
          if (!this.user.cellulare) { console.log('manca this.user.cellulare'); pass = false }
          if (!this.user.email)     { console.log('manca this.user.email');     pass = false }
          if (!this.user.email2)    { console.log('manca this.user.email2');    pass = false }
          
          break
        case 3:
          
          if (!this.user.nascita.datanascita)          { console.log('manca this.user.nascita.datanascita');          pass = false }
          //if (!this.user.nascita.codicefiscale)        { console.log('manca this.user.nascita.codicefiscale');        pass = false }
          if (!this.user.nascita.cittanascita)         { console.log('manca this.user.nascita.cittanascita');         pass = false }
          //if (!this.user.nascita.provincianascita)     { console.log('manca this.user.nascita.provincianascita');     pass = false }
          if (!this.user.nascita.nazionenascita)       { console.log('manca this.user.nascita.nazionenascita');       pass = false }
          if (!this.user.residenza.indirizzoresidenza) { console.log('manca this.user.residenza.indirizzoresidenza'); pass = false }
          if (!this.user.residenza.cittaresidenza)     { console.log('manca this.user.residenza.cittaresidenza');     pass = false }
          if (!this.user.residenza.capresidenza)       { console.log('manca this.user.residenza.capresidenza');       pass = false }
          //if (!this.user.residenza.provinciaresidenza) { console.log('manca this.user.residenza.provinciaresidenza'); pass = false }
          if (!this.user.residenza.nazioneresidenza)   { console.log('manca this.user.residenza.nazioneresidenza');   pass = false }
          if (!this.user.residenza.cittadinanza)       { console.log('manca this.user.residenza.cittadinanza');       pass = false }

          if (this.$i18n.locale === 'it') {
            if (!this.user.nascita.codicefiscale)        { console.log('manca this.user.nascita.codicefiscale');        pass = false }
            if (!this.user.nascita.provincianascita)     { console.log('manca this.user.nascita.provincianascita');     pass = false }
            if (!this.user.residenza.provinciaresidenza) { console.log('manca this.user.residenza.provinciaresidenza'); pass = false }
          }
          
          break
        case 4:
          
          if (!this.user.studio.titolostudio)    { console.log('manca this.user.studio.titolostudio');     pass = false }
          if(this.user.studio.titolostudio != 'Non ho alcun diploma di maturità') {
            if (!this.user.studio.annotitolo)      { console.log('manca this.user.studio.annotitolo');       pass = false }
            if (!this.user.studio.cittaistituto)   { console.log('manca this.user.studio.cittaistituto');    pass = false }
            if (!this.user.studio.nazioneistituto) { console.log('manca this.user.studio.nazioneistituto');  pass = false }
            if (!this.user.studio.nomeistituto && !this.user.studio.istituto) {
              console.log('manca this.user.studio.nomeistituto/this.user.studio.istituto')
              pass = false
            }
          }
          //if (!this.user.studio.nomeistituto)    { console.log('manca this.user.studio.nomeistituto');     pass = false }
          if (!this.user.studio.altrescuole)     { console.log('manca this.user.studio.altrescuole');      pass = false }
          if (!this.user.studio.conosciutoalma)  { console.log('manca this.user.studio.conosciutoalma');   pass = false }

          if (this.user.studio.titolostudio === 'Diploma di Scuola Superiore' && (
            !this.user.studio.indirizzostudio ||
            (this.user.studio.indirizzostudio === 'Altro (specificare)' && !this.user.studio.altroind)
          )) {
            console.log('manca this.user.studio.indirizzostudio/this.user.studio.altroind')
            pass = false
          }

          if (
            this.user.studio.nazioneistituto === 'Italia' &&
            (!this.user.studio.regioneistituto || !this.user.studio.provinciaistituto)
          ) {
            console.log('manca this.user.studio.ragioneistituto/this.user.studio.provinciaistituto')
            pass = false
          }

          if (this.user.studio.altrescuole === 'Si' && !this.user.studio.altrascuolacucina) {
            console.log('manca this.user.studio.altrascuolacucina')
            pass = false
          }

          if (this.user.studio.conosciutoalma === 'Altro' && !this.user.studio.altroCA) {
            console.log('manca this.user.studio.altroCA')
            pass = false
          }
          
          break
        case 5:
          
          if (!this.fileData) { console.log('manca il curriculum'); pass = false }
          
          break
        case 6:
          
          if (!this.user.documentoRic.tipoDoc)  { console.log('manca this.user.documentoRic.tipoDoc');  pass = false }
          if (!this.user.documentoRic.numero)   { console.log('manca this.user.documentoRic.numero');   pass = false }
          if (!this.user.documentoRic.dataRil)  { console.log('manca this.user.documentoRic.dataRil');  pass = false }
          if (!this.user.documentoRic.dataScad) { console.log('manca this.user.documentoRic.dataScad'); pass = false }
          // if (this.user.documentoRic.comuneRilascio.Name == 'comuneNotFound' && !this.user.documentoRic.ente)     { console.log('manca this.user.documentoRic.ente');     pass = false }
          // if (!this.user.documentoRic.statoRilascio.Name)     { console.log('manca this.user.documentoRic.statoRilascio');     pass = false }
          // if (!this.user.documentoRic.comuneRilascio)     { console.log('manca this.user.documentoRic.comuneRilascio');     pass = false }
          
          if (!this.fileDataDoc) { console.log('manca il documento'); pass = false }
          
          break
        case 7:
          // ?
          
          break
      }

      return pass
    },
    handleBeforeUnload(event) {
      event.preventDefault();
    },
    setuserdata: function() {
        var a={ };
        a.FirstName=this.user.nome;
        a.LastName=this.user.cognome;
        a.Sesso__c=this.user.sesso;
        a.Phone=this.user.telefono;
        a.PersonMobilePhone=this.user.cellulare;
        a.Phone_2__c=this.user.telefono2;
        a.PersonEmail=this.user.email;
        a.Email__c=this.user.email2;
        a.Privacy_Consenso_Acquisito__pc = this.user.privacy;
        a.Privacy_Marketing__pc = this.user.marketing;

        var toUTC = new Date(this.user.nascita.datanascita).toUTCString();
        console.log(toUTC);
        a.Data_di_nascita__c = moment(toUTC).unix()*1000;

        a.Cliente_senza_CF_Italiano__c = (this.user.hacodFisc == 'No'? true : false)
        if(!a.Cliente_senza_CF_Italiano__c && this.user.nascita.codicefiscale != '') a.CF__c = this.user.nascita.codicefiscale;
        
        // if(this.user.hacodFisc == 'YES' || this.$i18n.locale=='it')a.CF__c = this.user.nascita.codicefiscale;
        // if(this.$i18n.locale=='en') a.Cliente_senza_CF_Italiano__c = (this.user.hacodFisc == 'No'? true : false);
        
        a.Citta_di_nascita__c = this.user.nascita.cittanascita;
        a.Provincia_di_Nascita__c =  this.$i18n.locale=='it'?this.user.nascita.provincianascita:'EE - For Foreign Country';
        a.Nazione_di_nascita__c = this.user.nascita.nazionenascita;

        a.PersonMailingStreet=this.user.residenza.indirizzoresidenza;
        a.PersonMailingCity=this.user.residenza.cittaresidenza;
        a.PersonMailingPostalCode=this.user.residenza.capresidenza;
        a.PersonMailingState=this.$i18n.locale=='it'?this.user.residenza.provinciaresidenza:'EE - For Foreign Country';
        a.PersonMailingCountry=this.user.residenza.nazioneresidenza;
        a.Cittadinanza__c = this.user.residenza.cittadinanza;
        a.Cittadinanza_2__c = this.user.residenza.cittadinanza2;

        a.Titolo_di_studio__c  = this.user.studio.titolostudio;
        a.Indirizzo_titolo__c  = this.user.studio.indirizzostudio;
        a.Altro_studio__c  = this.user.studio.altroind;
        a.Anno_diploma__c  = this.user.studio.annotitolo;
        a.Nome_Istituto__c  = this.user.studio.nomeistituto;
        a.Citta_Istituto__c  = this.user.studio.cittaistituto;
        a.Provincia_Istituto__c = this.user.studio.provinciaistituto;
        a.Regione_Istituto__c = this.user.studio.regioneistituto;
        a.Livello_Inglese__c = this.user.studio.levelOfEnglish

        a.Nazione_Istituto__c  = this.user.studio.nazioneistituto;
        if(this.user.studio.istituto != '' && this.user.studio.istituto) a.Istituto__c = this.user.studio.istituto;
        a.isAltreScuole__c  = false;
        if (this.user.studio.altrescuole=='Si') a.isAltreScuole__c  = true;
        a.Altre_Scuole_Cucina__c = this.user.studio.altrascuolacucina;
        a.ConosciutoALMA__c = this.user.studio.conosciutoalma;
        a.Altro_conosciuto_Alma__c = this.user.studio.altroCA;

        a.Ragione_Sociale__c=this.user.fatturazione.ragionesociale;
        a.PIVA__c=this.user.fatturazione.piva;
        a.Codice_Fiscale__c=this.user.fatturazione.codicefiscale;
        a.PEC__c=this.user.fatturazione.pec;
        a.Codice_SDI__c=this.user.fatturazione.sdi;
        a.BillingStreet=this.user.fatturazione.indirizzo;
        a.BillingCity=this.user.fatturazione.citta;
        a.BillingPostalCode=this.user.fatturazione.cap;
        a.BillingState=this.user.fatturazione.provincia;
        a.BillingCountry=this.user.fatturazione.paese;

        a.Tipo_Documento__c=this.user.documentoRic.tipoDoc;
        a.Numero__c=this.user.documentoRic.numero;
        var toUTCdtril = new Date(this.user.documentoRic.dataRil).toUTCString();
        var toUTCdtScad = new Date(this.user.documentoRic.dataScad).toUTCString();
        a.Data_Rilascio__c=moment(toUTCdtril).unix()*1000;
        a.Data_Scadenza__c=moment(toUTCdtScad).unix()*1000;

        if(this.user.documentoRic.ente != '') a.Ente_Rilasciato__c=this.user.documentoRic.ente;
        a.Stato_di_Rilascio__c=this.user.documentoRic.statoRilascio.Id;
        a.Comune_di_Rilascio__c=this.user.documentoRic.comuneRilascio.Id;

        a.Language__c = this.$i18n.locale;
        a.Status__c='Candidato';

        if (this.$store.state.userprofile.Id) a.Id=this.$store.state.userprofile.Id;
        else a.AccountSource = 'Form Candidatura';

        a.Username__pc = this.user.username;
        a.Password__pc = this.user.password;
        var self=this;
        console.log('Account prima del setCandidatura', a)

        this.$store.dispatch('setCandidatura', { account: a, oppty: { Name:'portaleCandidature' }, corso: this.corso,  edizione: this.edizione })
        .then(function(resp) {
          console.log('cand',resp)
          var data={ username: self.user.username, password: self.user.password };
          self.$store.dispatch('login', data)
          .then(function() {
            var data2={ username: self.$store.state.connection.username, crypt: self.$store.state.connection.userdata };
            self.$store.dispatch('getProfile', data2)
            .then(function() {
              console.log('profile recoveder');

              console.log('doUpload:'+self.$store.state.idOppty);
              var data={ username: self.$store.state.connection.username, callname:'uploadDocument', crypt: self.$store.state.connection.userdata,
                parentId: self.$store.state.idOppty,  filename: self.myfile.name,  payload: self.fileData,
              };
              console.log('data',data)

              var data2={ username: self.$store.state.connection.username, callname:'uploadDocumentId', crypt: self.$store.state.connection.userdata,
                parentId: self.$store.state.idOppty,  filename: self.myfileDoc.name,  payload: self.fileDataDoc, tipo: self.user.documentoRic.tipoDoc,
                scadenza: moment(toUTCdtScad).unix()*1000, name: self.user.documentoRic.numero
              };
              console.log('data2',data2)

              var data3
              console.log('self.myCertificate.name', self.myCertificate.name)
              if(self.myCertificate.name) {
                data3={ username: self.$store.state.connection.username, callname:'uploadDocumentId', crypt: self.$store.state.connection.userdata,
                  parentId: self.$store.state.idOppty,  filename: self.myCertificate.name,  payload: self.fileDataCertificate,
                  scadenza: moment(toUTCdtScad).unix()*1000, tipo: 'Certificato Lingua', name: 'Certificato Lingua Inglese'
                };
              }
              console.log('data3',data3)
              
              apiclient.getcandidatura( self.$store.state.connection, data, self.uploadProgress)
              .then( function(resp) {
                console.log('doUpload:');
                console.log(resp);
                var dataHB={username: self.$store.state.connection.username, callname:'sendtoHB', crypt: self.$store.state.connection.userdata, account: a, edizionCorso: self.$store.state.productcode};
                self.$store.dispatch('sendtoHB', dataHB)
                .then(function(){
                  apiclient.getcandidatura( self.$store.state.connection, data2, self.uploadProgress)
                  .then( function(resp) {
                    console.log('doUpload2:');
                    console.log(resp);  
                    localStorage.removeItem('candidatura');
                    self.$store.commit('SET_ROUTERLOCK', false);
                    self.$router.push({ name: 'Candidato' });
                    self.uploading = false;
                    if(data3) {
                      apiclient.getcandidatura( self.$store.state.connection, data3, self.uploadProgress).then( function(resp) {
                        console.log('doUpload:');
                        console.log(resp);
                      }).catch(function(err) {
                        self.uploading = false;
                        console.log('problemi:')
                        console.log(err)
                        console.log('problemi:')
                        console.log(err.response.data)
                        console.log('Errori nella candidatura (codice 03)')
                        alert('Errori nella candidatura (codice 03)');
                      });
                    }
                    window.removeEventListener("beforeunload", this.handleBeforeUnload);
                    alert('Candidatura andata a buon fine!');
                  }).catch(function(err) {
                    self.uploading = false;
                    console.log('problemi:')
                    console.log(err)
                    console.log('problemi:')
                    console.log(err.response.data)
                    console.log('Errori nella candidatura (codice 01)')
                    alert('Errori nella candidatura (codice 01)');
                  });
                }).catch(function(err) {
                  self.uploading = false;
                  console.log('problemi:')
                  console.log(err)
                  console.log('problemi:')
                  console.log(err.response.data)
                  console.log('Errori nella candidatura (codice 02)')
                  alert('Errori nella candidatura (codice 02)');
                });
              }).catch(function(err) {
                self.uploading = false;
                console.log('problemi:')
                console.log(err)
                console.log('Errori nella candidatura (codice 03)')
                alert('Errori nella candidatura (codice 03)');
              });
            }).catch(function(err) {
                self.uploading = false;
                console.log('problemi:')
                console.log(err)
                console.log('problemi:')
                console.log(err.response.data)
                console.log('Errori nella candidatura (codice 04)')
                alert('Errori nella candidatura (codice 04)');
              });
          }).catch(function(err) {
              self.uploading = false;
              console.log('problemi:')
              console.log(err)
              console.log('problemi:')
              console.log(err.response.data)
              console.log('Errori nella candidatura (codice 05)')
              alert('Errori nella candidatura (codice 05)');
            });
        }).catch(function(err) {
            self.uploading = false;
            console.log('problemi:')
            console.log(err)
            console.log('problemi:')
            console.log(err.response.data)
            console.log('Errori nella candidatura (codice 06)')
            alert('Errori nella candidatura (codice 06)');
      });
    },
    getuserdata: function() {
        this.user.nome=this.$store.state.userprofile.FirstName;
        this.user.cognome=this.$store.state.userprofile.LastName;
        this.user.sesso=this.$store.state.userprofile.Sesso__c;
        this.user.telefono=this.$store.state.userprofile.Phone;
        this.user.cellulare=this.$store.state.userprofile.PersonMobilePhone;
        this.user.telefono2=this.$store.state.userprofile.Phone_2__c;
        this.user.email=this.$store.state.userprofile.PersonEmail;
        this.user.email2=this.$store.state.userprofile.Email__c;

        this.user.nascita.datanascita=this.$store.state.userprofile.Data_di_nascita__c;
        this.user.nascita.codicefiscale=this.$store.state.userprofile.CF__c ;
        this.user.nascita.cittanascita=this.$store.state.userprofile.Citta_di_nascita__c;
        this.user.nascita.provincianascita=this.$store.state.userprofile.Provincia_di_Nascita__c;
        this.user.nascita.nazionenascita=this.$store.state.userprofile.Nazione_di_nascita__c;

        this.user.residenza.indirizzoresidenza=this.$store.state.userprofile.PersonMailingStreet;
        this.user.residenza.cittaresidenza=this.$store.state.userprofile.PersonMailingCity;
        this.user.residenza.capresidenza=this.$store.state.userprofile.PersonMailingPostalCode;
        this.user.residenza.provinciaresidenza=this.$store.state.userprofile.PersonMailingState;
        this.user.residenza.nazioneresidenza=this.$store.state.userprofile.PersonMailingCountry;
        this.user.residenza.cittadinanza=this.$store.state.userprofile.Cittadinanza__c;
        this.user.residenza.cittadinanza2=this.$store.state.userprofile.Cittadinanza_2__c;

        this.user.studio.titolostudio=this.$store.state.userprofile.Titolo_di_studio__c;
        this.user.studio.annotitolo=this.$store.state.userprofile.Anno_diploma__c;
        this.user.studio.nomeistituto=this.$store.state.userprofile.Nome_Istituto__c;
        this.user.studio.cittaistituto=this.$store.state.userprofile.Citta_Istituto__c;
        this.user.studio.provinciaistituto=this.$store.state.userprofile.Provincia_Istituto__c;
        this.user.studio.nazioneistituto=this.$store.state.userprofile.Nazione_Istituto__c;
        this.user.studio.altrescuole=(this.$store.state.userprofile.isAltreScuole__c?'Si':'No');
        this.user.studio.altrascuolacucina=this.$store.state.userprofile.Altre_Scuole_Cucina__c;
        this.user.studio.conosciutoalma=this.$store.state.userprofile.ConosciutoALMA__c;

        this.user.fatturazione.ragionesociale=this.$store.state.userprofile.Ragione_Sociale__c;
        this.user.fatturazione.piva=this.$store.state.userprofile.PIVA__c;
        this.user.fatturazione.codicefiscale=this.$store.state.userprofile.Codice_Fiscale__c;
        this.user.fatturazione.pec=this.$store.state.userprofile.PEC__c;
        this.user.fatturazione.sdi=this.$store.state.userprofile.Codice_SDI__c;
        this.user.fatturazione.indirizzo=this.$store.state.userprofile.BillingStreet;
        this.user.fatturazione.citta=this.$store.state.userprofile.BillingCity;
        this.user.fatturazione.cap=this.$store.state.userprofile.BillingPostalCode;
        this.user.fatturazione.provincia=this.$store.state.userprofile.BillingState;
        this.user.fatturazione.paese=this.$store.state.userprofile.BillingCountry;

        this.user.username=this.$store.state.userprofile.Username__pc;
        this.user.password=this.$store.state.userprofile.Password__pc;

    }
  }
};
</script>

<style scoped>
  .logerr    { color: red  }
  .mrow { justify-content: center }

  p { margin:10px 0 5px 0 }

  h4 { margin-top: 20px }
</style>