<template>
    <div class="home">
        <navbar :menulist="$store.state.mainmenu" />

        <div class="row mx-auto pt-2">
            <div class="col-lg-12 col-md-12 col-xs-12 ml-2">
                <button type="button" class="btn btn-outline-dark btn-sm" @click="abort">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg>
                    {{$t('Annulla')}}
                </button>
            </div>
        </div>

        <div :class="{disabled: !this.$store.getters.isEcommerceVisible}">
            <h2 class="welcome">{{$t('Riepilogo')}}</h2>
            <div class="row mx-auto">
                <div class="col-sm-1"></div>
                <div class="col">
                    <almatable :cols="cols" :rows="prods" />
                </div>
                <div class="col-sm-1"></div>
            </div>

            <div class="row mx-auto">
                <!--
                    <div class="col-8"></div>
                    <div class="col-3">
                        <div class="row justify-right"><h3 class="welcome">Totale finale</h3><payment/div>
                        <div class="row justify-right"><p style="font-size: 18px">{{totalone | amount}}</p></div>
                    </div>
                -->
                <div class="col-md">
                    <div class="row mx-auto p-3 justify-content-center">
                        <h3 class="font-weight-bold">{{$t('TotCart')}}: &#160;</h3>
                        <span class="">{{totalone | amount}}</span>
                    </div>
                </div>
            </div>

            <div class="row mx-auto" style="justify-content: center">
                <button class="btn btn-primary" @click="payment" :disabled="this.prods.length == 0 || isPaying">{{$t('ProcediPagamento')}}</button>
            </div>
        </div>
        <footerline />
    </div>
</template>

<script>
    // @ is an alias to /src
    import navbar       from '@/components/navbar.vue'
    import footerline   from '@/components/footerline.vue'
    import almatable    from '@/components/almatable.vue'

    import numeral        from 'numeral'
    import { loadStripe } from '@stripe/stripe-js'

    export default {
        name: 'EcommerceRiepilogo',
        components: { navbar, footerline, almatable },
        props: { badgeprod: [] },
        data: function() {
            return {
                prods: [],
                cols: [
                    { title: this.$i18n.t('Prodotto'),       name: 'Description'                   },
                    { title: this.$i18n.t('Quantità'),       name: 'qty'                           },
                    { title: this.$i18n.t('PUnita'), name: 'Prezzo__c', filter: 'currency' },
                    { title: this.$i18n.t('Totale'),         name: 'tot',       filter: 'currency' }
                ],
                totalone: 0,
                isPaying: false
            }
        },
        filters: {
            amount: v => numeral(v).format('0,0[.]00 $')
        },
        mounted: function() {
            this.prods = this.badgeprod ? [this.badgeprod] : this.$store.state.carrelloEcommerce
            console.log('this.prods',this.prods)

            // per test
            this.prods.forEach(p => {
                p.tot = (p.Prezzo__c * p.qty) || 0

                this.totalone += p.tot
            })
        },
        methods: {
            abort: function() {
                if(this.badgeprod) this.$router.push({ name: 'EcommerceBadge' })
                else this.$router.push({ name: 'EcommerceShop' })
            },

            payment: async function() {
                console.log('payment')
                this.isPaying = true

                // STRIPE in TEST MODE
                // const stripe = await loadStripe('pk_test_6WyfbLIpJcjksu8VUjrCII0r00a8gwax14')

                // STRIPE in PRODUZIONE
                const stripe = await loadStripe('pk_live_ZFzoczvtTxJ7QdrInQClJFrM00PSrKrcot')
                console.log(stripe)
                
                var importo = this.totalone, payload = { tot: importo, locale: 'ja' }

                if (this.badgeprod) payload.prods = [this.badgeprod]

                // chiamata api cCommerce.createCheckoutSession -> stripePayment
                
                this.$store.dispatch('stripePayment', payload)
                .then(result => {
                    console.log(result)
                    
                    console.log('stripePayment')

                    if (result) {
                        var data = JSON.parse(this.decodeHTML(result))

                        this.createOppty(data.id, data.payment_intent, importo)
                        .then(() => {
                            stripe.redirectToCheckout({
                                // Make the id field from the Checkout Session creation API response
                                // available to this file, so you can provide it as parameter here
                                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                                sessionId: data.id
                            }).then(e => {
                                // If `redirectToCheckout` fails due to a browser or network
                                // error, display the localized error message to your customer
                                // using `data.error.message`.
                                console.log(e.error.message)
                            })
                        })
                    }
                })
                
            },
            createOppty: function(sessionId, paymentIntent, importo) {
                return new Promise(res => {
                    var products = [], payload = {} //, oppty = { Name: 'eCommerce' }

                    this.prods.forEach((p,i) => {
                        console.log('CODICE ALIQUOTA',p.Codice_Aliquota__c)
                        var prod = {
                        /*
                        // Note__c: p.note,
                        Prezzo__c: p.Prezzo__c,
                        Quantita__c: p.qty,
                        ProductCode: p.ProductCode,
                        Id: p.Id,
                        Description: p.Description
                        */
                        IdProd: p.Id, //per creare collegamento riga ordine con prodotto
                        name: p.Name,
                        price: p.Prezzo__c,
                        qty: p.qty,
                        img: null,
                        descr: p.Description,
                        size: null,
                        //    code: null
                        code: p.ProductCode,
                        codiceAliquota: p.Codice_Aliquota__c,
                        regimeIva: p.Regime_Iva__c,
                        ordinatore: parseInt(i)+1,
                        tempoApprovvigionamento: p.Tempo_approvvigionamento__c
                        }
                        if(p.Info_Prenotazione__c) prod['infoPrenotazione'] = p.Info_Prenotazione__c

                        products.push(prod)
                    })

                    payload = { 
                        products: products, 
                        sessionId: sessionId, 
                        paymentIntent: paymentIntent, 
                        importo: importo, 
                        isRicarica: this.badgeprod ? true : false
                    }
                    if(this.$store.state.opptyCorso) payload['opptyCorso'] = this.$store.state.opptyCorso

                    console.log('payload before dispatch',payload)
                    console.log(payload.products[0])

                    this.$store.dispatch('createOpptyEcommerce', payload)
                    .then(() => {
                        res()

                        // pulire carrello? sì
                        this.$store.commit('RESET_CARRELLO')
                    })
                })
            },

            decodeHTML: function(el) {
                var parser = new DOMParser,
                    element = document.createElement('textarea')

                element.innerHTML = el

                var text = parser.parseFromString(element.value, 'text/html')

                el = text.body.textContent

                return el
            }
        }
    }
</script>

<style>
    .disabled{
        pointer-events: none;
        opacity: 0.4;
    }
</style>