<template>
  <div>
    <div class="row mx-auto d-flex justify-content-center">
            <div class="col-lg-8 col-md-8 col-xs-12">
                <p style="font-size:18px;;margin-top:10px;">
                    {{$t('HaiUnaLogin')}}
                    <button class="btn btnBlue" style=" border-radius:10px" v-on:click.prevent="$emit('login')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill texttop" viewBox="0 0 16 16">
                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                      </svg> &nbsp; {{$t('Accedi')}}
                    </button>
                </p>
            </div>
    </div>
  <div class="row mx-auto d-flex justify-content-center">
    <div class="col-lg-8 col-md-8 col-xs-12">
        <div class="panel panel-primary" >
          <div class="panel-heading col-sm-12 col-md-10">
            <h3 class="panel-title">{{$t('DatiPersonali')}}</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('Nome')}}* </label>
                  <input
                    v-model="user.nome"
                    maxlength="100"
                    required
                    type="text"
                    class="form-control"
                    v-bind:class="!user.nome?'is-invalid':'is-valid'"
                    :placeholder="$t('Nome')"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('Cognome')}}*</label>
                  <input
                    v-model="user.cognome"
                    maxlength="100"
                    type="text"
                    required
                    class="form-control"
                    v-bind:class="!user.cognome?'is-invalid':'is-valid'"
                    :placeholder="$t('Cognome')"
                  />
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-sm-12 col-md-5">
                <div class="form-check">
                  <label class="control-label" required style="padding-right:35px;">{{$t('Sesso')}}*</label>
                  <input v-model="user.sesso" class="form-check-input" 
                    v-bind:class="!user.sesso?'is-invalid':'is-valid'"
                    type="radio" name="F" value="F" />
                  <label class="form-check-label" style="padding-right:45px;">F</label>
                  <input v-model="user.sesso" class="form-check-input" 
                    v-bind:class="!user.sesso?'is-invalid':'is-valid'"
                    type="radio" name="M" value="M" />
                  <label class="form-check-label">M</label>
                </div>
              </div>
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('CodiceFiscale')}}*</label>
                  <input 
                    v-model="user.nascita.codicefiscale" 
                    v-bind:class="!user.nascita.codicefiscale || this.user.nascita.codicefiscale.length > 16?'is-invalid':'is-valid'"
                    maxlength="100" type="text" required class="form-control" placeholder="CF" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('Cellulare')}}*</label>
                  <input
                    v-model="user.cellulare" 
                    maxlength="100"
                    required
                    type="tel"
                    class="form-control"
                    v-on:keypress="isNumber($event)"
                    v-bind:class="!user.cellulare?'is-invalid':'is-valid'"
                    :placeholder="$t('Cellulare')"
                  />
                </div>
              </div>
              </div>

            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('E-Mail')}}*</label>
                  <input
                    v-model="user.email" 
                    maxlength="100"
                    required
                    type="email"
                    class="form-control"
                    v-bind:class="!user.email || !regEmail.test(user.email)?'is-invalid':'is-valid'"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('Conferma Email')}}*</label>
                  <input
                    v-model="user.confermaemail" 
                    maxlength="100"
                    required
                    type="email"
                    class="form-control"
                    v-bind:class="!user.confermaemail || user.confermaemail != user.email?'is-invalid':'is-valid'"
                    placeholder="Email"
                    onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('IndirizzoResidenza')}}*</label>
                  <input
                    v-model="user.residenza.indirizzoresidenza"              
                    maxlength="100"
                    type="text"
                    required
                    class="form-control"
                    v-bind:class="!user.residenza.indirizzoresidenza?'is-invalid':'is-valid'"
                    :placeholder="$t('Indirizzo Residenza')"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('CittàdiResidenza')}}*</label>
                  <input
                    v-model="user.residenza.cittaresidenza"              
                    maxlength="100"
                    type="text"
                    required
                    class="form-control"
                    v-bind:class="!user.residenza.cittaresidenza?'is-invalid':'is-valid'"
                    :placeholder="$t('CittàdiResidenza')"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('CAP')}}*</label>
                  <input
                    v-model="user.residenza.capresidenza"              
                    maxlength="100"
                    type="text"
                    required
                    class="form-control"
                    v-bind:class="!user.residenza.capresidenza?'is-invalid':'is-valid'"
                    :placeholder="$t('CAP')"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-5"  v-if="$i18n.locale=='it'">
                <div class="form-group">
                  <label class="control-label">{{$t('ProvinciadiResidenza')}}*</label>
                  <select v-model="user.residenza.provinciaresidenza" 
                    class="form-control" v-bind:class="!user.residenza.provinciaresidenza?'is-invalid':'is-valid'"
                    v-bind:title="$t('Scegliunaopzione')" required>
                    <option v-for="v in getPicklistValues('Lead','Provincia_Picklist__c')" v-bind:key="v" v-bind:value="v">{{v}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('NazionediResidenza')}}</label>
                  <select v-model="user.residenza.nazioneresidenza" 
                    class="form-control" v-bind:class="!user.residenza.nazioneresidenza?'is-invalid':'is-valid'"
                    v-bind:title="$t('Scegliunaopzione')" required>
                    <option v-for="v in getPicklistValues('Lead','Nazione_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('DatadiNascita')}}*</label>
                  <input 
                    v-model="user.nascita.datanascita" 
                    v-bind:class="!user.nascita.datanascita?'is-invalid':'is-valid'"
                    type="date" required class="form-control" placeholder="dd-mm-yyyy" />
                </div>
              </div>
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('CittàdiNascita')}}*</label>
                  <input
                    v-model="user.nascita.cittanascita" 
                    maxlength="100"
                    type="text"
                    required
                    class="form-control"
                    v-bind:class="!user.nascita.cittanascita?'is-invalid':'is-valid'"
                    :placeholder="$t('Città')"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('ProvinciadiNascita')}}*</label>
                  <select v-model="user.nascita.provincianascita" class="form-control" 
                      v-bind:class="!user.nascita.provincianascita?'is-invalid':'is-valid'" v-bind:title="$t('Scegliunaopzione')" required>
                    <option v-for="v in getPicklistValues('Lead','Provincia_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">{{$t('NazionediNascita')}}*</label>
                  <select v-model="user.nascita.nazionenascita" 
                    class="form-control" v-bind:class="!user.nascita.nazionenascita?'is-invalid':'is-valid'"
                    v-bind:title="$t('Scegliunaopzione')" required>
                    <option v-for="v in getPicklistValues('Lead','Nazione_Picklist__c')" v-bind:key="v" v-bind:value="v">{{$t(v)}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                    <label class="control-label">{{$t('AttualeOccupazione')}}*</label><br>
                    <input type="radio" id="o1" name="occupazione" value="Operatore di Cucina" v-model="user.studio.attualeoccupazione">
                    <label class="form-check-label" for="o1">{{$t('OperatoreCucina')}}</label><br>
                    <input type="radio" id="o2" name="occupazione" value="Operatore di Pasticceria" v-model="user.studio.attualeoccupazione">
                    <label class="form-check-label" for="o2">{{$t('OperatorePasticceria')}}</label><br>
                    <input type="radio" id="o3" name="occupazione" value="Operatore di Sala" v-model="user.studio.attualeoccupazione">
                    <label class="form-check-label" for="o3">{{$t('OperatoreSala')}}</label><br>
                    <input type="radio" id="o4" name="occupazione" value="F&B Manager" v-model="user.studio.attualeoccupazione">
                    <label class="form-check-label" for="o4" >{{$t('FBManager')}}</label><br>
                    <input type="radio" id="o5" name="occupazione" value="Titolare di attività ristorative" v-model="user.studio.attualeoccupazione">
                    <label class="form-check-label" for="o5">{{$t('AttivitàRistorative')}}</label><br>
                    <input type="radio" id="o6" name="occupazione" value="Altro" v-model="user.studio.attualeoccupazione">
                    <label class="form-check-label" for="o6">{{$t('Altro')}}</label><br>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel panel-primary">
          <div class="panel-heading col-sm-12 col-md-10">
            <h3 class="panel-title">{{$t('Letuecredenziali')}}</h3>
          </div>
          <div class="panel-body">
            <div class="row" style="text-align: center; margin: 5px">
                <div class="col-sm-12 col-md-10">
                  <mark>{{$t('TestoRegistrazione')}}</mark>
                </div>
              </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label class="control-label">USERNAME</label>
                  <input disabled
                    v-model="user.email"
                    v-bind:class="!user.email?'is-invalid':'is-valid'"
                    maxlength="100" type="text" class="form-control" placeholder="Username" />
                </div>
              </div>
              <div class="col-sm-12 col-md-5">
                <div class="form-group ">
                  <label class="control-label">PASSWORD</label>
                  <div class="input-group">
                    <input
                      v-model="user.password"
                      maxlength="100"
                      v-bind:type="reveal?'text':'password'"
                      class="form-control"
                      v-bind:class="!user.password?'is-invalid':'is-valid'"
                      placeholder="Password"
                    />
                    <div class="input-group-append" v-on:click="chgReveal">
                      <span class="input-group-text">
                        <span v-if="!reveal" class="oi oi-eye"></span>
                        <span v-if="reveal" class="oi oi-code"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-5">
                </div>
              <div class="col-sm-12 col-md-5">
                <div class="form-group ">
                  <label class="control-label">{{$t('ConfermaPassword')}}</label>
                  <div class="input-group">
                    <input
                      v-model="user.pwd2"
                      maxlength="100"
                      v-bind:type="reveal?'text':'password'"
                      class="form-control"
                      v-bind:class="!user.pwd2 || user.password != user.pwd2?'is-invalid':'is-valid'"
                      placeholder="Password"
                    />
                    <div class="input-group-append" v-on:click="chgReveal">
                      <span class="input-group-text">
                        <span v-if="!reveal" class="oi oi-eye"></span>
                        <span v-if="reveal" class="oi oi-code"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="form-group paddingpiu custom-checkbox">
                    <label class="control-label" style="padding-right:25px;" v-if="this.$i18n.locale=='it'">
                    <span style=" font-weight: bold;margin: 8px; color: red;">*</span><a href="https://www.alma.scuolacucina.it/privacy-policy/"  target="blank_" style="cursor:pointer;text-decoration: underline;font-size:16px;font-weight:bold;">Presa visione dell’Informativa  </a>
                    in ottemperanza al Regolamento (UE) 2016/679 dichiaro che:<br>
                    Acconsento al trattamento dei miei dati per ricevere informazioni su iniziative della Scuola e/o dei propri partner, corsi, attività ed eventi che potrebbero essere di mio interesse</label>
                  <label class="control-label" style="padding-right:25px;" v-else>
                    <span style="font-weight: bold;margin: 8px; color: red;">*</span><a href="https://www.alma.scuolacucina.it/privacy-policy/" target="blank_" style="cursor: pointer; text-decoration: underline; font-size: 16px; font-weight: bold;"> Having read the information </a>
                     in compliance with Regulation (EU) 2016/679 I declare that: <br>
                     I consent to the processing of my data to receive information on initiatives of the School and / or its partners, courses, activities and events that may be of interest to me</label>
                    <div style="margin-left:18px;">
                      <input for="privacy_acceptance" class="form-check-input" type="radio" name="true" v-bind:value="true"  v-model="user.privacy"/>
                      <label class="form-check-label" style="padding-right:45px;">{{$t('Si')}}</label>
                      <input for="privacy_acceptance" class="form-check-input" type="radio" name="false" v-bind:value="false"  v-model="user.privacy"/>
                      <label class="form-check-label">No</label>
                    </div> 
                </div>
                <!--div class="form-group paddingpiu custom-checkbox" v-else>
                    <label  class="control-label" style="padding-right:25px;">I have read and accepted <a href="https://www.alma.scuolacucina.it/privacy-policy/"  target="blank_"><b>Privacy Policy</b></a></label>
                    <input for="privacy_acceptance" class="form-check-input" type="radio" name="true" value="true"  v-model="user.privacy"/>
                    <label class="form-check-label" style="padding-right:45px;">Yes</label>
                    <input for="privacy_acceptance" class="form-check-input" type="radio" name="false" value="false"  v-model="user.privacy"/>
                    <label class="form-check-label">No</label>
                </div-->
              <div class="col-sm-12 col-md-5"></div>
            </div>
            <div class="row">
                <div class="form-group paddingpiu custom-checkbox">
                    <label class="control-label" style="padding-right:25px;" v-if="this.$i18n.locale=='it'">
                    <span style=" font-weight: bold;margin: 8px; color: red;">*</span>Per finalità di comunicazione promozionale e/o istituzionale e l’utilizzo di immagini e video, inerenti alla Scuola di Cucina, eventi, lezioni nel sito Web ALMA, all’interno di Facebook e di YouTube, e di tutti gli altri strumenti multimediali adatti a rappresentare l’immagine della Scuola. <br>
                                      Per finalità di marketing quali invio di informazioni su iniziative della  Scuola  (es.  newsletter,  comunicazioni  promozionali,  corsi,  open  day, eventi, servizi, ecc.) e/o dei nostri partner, che potrebbero essere di interesse dell’utente, anche tramite modalità automatizzate (es. e-mail, sms)</label>
                  <label class="control-label" style="padding-right:25px;" v-else>
                    <span style=" font-weight: bold;margin: 8px; color: red;">*</span>For promotional and / or institutional communication purposes and the use of images and videos, relating to the Cooking School, events, lessons on the ALMA website, within Facebook and YouTube, and all other multimedia tools suitable for represent the image of the School.<br>
										For marketing purposes such as sending information on School initiatives (eg newsletters, promotional communications, courses, open days, events, services, etc.) and / or our partners, which may be of interest to the user, also through automated methods (e.g. e-mail, sms)</label>
                    <div style="margin-left:18px;">
                      <input for="marketing_acceptance" class="form-check-input" type="radio" name="true" v-bind:value="true"  v-model="user.marketing"/>
                      <label class="form-check-label" style="padding-right:45px;">{{$t('Si')}}</label>
                      <input for="marketing_acceptance" class="form-check-input" type="radio" name="false" v-bind:value="false"  v-model="user.marketing"/>
                      <label class="form-check-label">No</label>
                    </div> 
                </div>
              <div class="col-sm-12 col-md-5"></div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5"></div>
              <div class="col-sm-12 col-md-5"></div>
            </div>

            <div class="col-sm-12 col-md-10">
              <button v-if="uploading" class="btn btn-primary float-right" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </button> 
              <button v-if="uploading == false" v-bind:disabled="checkPanel1" class="btn btnBlue float-right" type="button" v-on:click.prevent="manda" >{{$t('Fine')}}</button>
            </div>

          </div>
        </div>

    </div>
  </div>
  </div>
</template>

<script>
//import apiclient from '@/apiclient.js'

import moment from 'moment'

export default {
  name: "formcandidatura",
  props: { 
    productcode:String
  },
  mounted: function() {
  },
  data: function() {
    return {
      regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regNumber:/^[0-9]+$/,
      myfile: {},
      fileData: '',
      bloccochiusura: true,
      step: 1,
      maxstep:7,
      reveal: false,
      uploading: false,
      user: {
        nome:'',
        cognome:'',
        sesso:'',
        cellulare:'',
        email:'',
        confermaemail: '',

        residenza: {
          indirizzoresidenza:'',
          cittaresidenza:'',
          capresidenza:'',
          provinciaresidenza:'',
          nazioneresidenza:''
        },

        nascita: {
          datanascita:'',
          codicefiscale:'',
          cittanascita:'',
          provincianascita:'',
          nazionenascita:'',
        },
        studio: {
          attualeoccupazione:''
        },
        username:'',
        password:'',
        pwd2:'',
        privacy: true,
        marketing: true

      }
    };
  },
 computed: {
    conosciutoalma: function() {
      if (this.$store.state.campi) {
        if (this.$store.state.campi.has('Account')) {
          console.log('oggetto')
          var af=this.$store.state.campi.get('Account')
          if (af.has('ConosciutoALMA__c')) {
            console.log('campo')
            var afv=af.get('ConosciutoALMA__c');
            console.log(afv)
            if (afv) return afv.values.split(';');
          }
        }
      }
      return [];
    },
    checkPanel1: function() {
      if (!this.user.nome) return true;
      if (!this.user.cognome) return true;
      if (!this.user.sesso) return true;
      if (!this.user.cellulare) return true;
      if (!this.user.email) return true;
      if (!this.user.confermaemail || this.user.confermaemail != this.user.email) return true;
      if (!this.user.residenza.indirizzoresidenza) return true;
      if (!this.user.residenza.cittaresidenza) return true;
      if (!this.user.residenza.capresidenza) return true;
      if (!this.user.residenza.provinciaresidenza) return true;
      if (!this.user.residenza.nazioneresidenza) return true;
      if (!this.user.studio.attualeoccupazione) return true;
      if (!this.user.nascita.datanascita) return true;
      if (!this.user.nascita.cittanascita) return true;
      if (!this.user.nascita.provincianascita) return true;
      if (!this.user.nascita.nazionenascita) return true;
      //if (!this.user.studio.titolostudio) return true;
      //if (!this.user.username) return true;
      if (!this.user.password) return true;
      if(!this.$store.state.userprofile.Id){
        if (!this.user.pwd2) return true;
        if (this.user.pwd2 !== this.user.password) return true;
      }
      if (!this.user.privacy) return true;
      if (!this.user.marketing) return true;
      return false;
    }
 },
  methods: {
    isNumber(e) {
        let char = String.fromCharCode(e.keyCode);
        if (/^[+][0-9]+|^[0-9]+/.test(char)) return true;
        else e.preventDefault();
    },
    chgReveal: function(){
      this.reveal = !this.reveal;
    },
    getPicklistValues: function(o,f) {
      if (this.$store.state.campi) {
        if (this.$store.state.campi.has(o)) {
          console.log('oggetto')
          var af=this.$store.state.campi.get(o)
          if (af.has(f)) {
            console.log('campo')
            var afv=af.get(f);
            console.log(afv)
            if (afv)return afv.values.split(';');
          }
        }
      }
      return [];
    },
    chiusura: function(event) {
      console.log('blocco chiusura');
      if (this.bloccochiusura) {
        event.preventDefault();
        if (confirm('Si desidera uscire?')) {
          event.returnValue='';
          this.$store.commit('SET_ROUTERLOCK', false);
        } else {
          event.returnValue=true;
        }
      }
    },
    manda: function() {
      if (confirm('Confermi e manda?')) {
        this.uploading = true;
        this.setuserdata();
        // eslint-disable-next-line no-undef
      }
    },
    setuserdata: function() {
        var a={ };
        a.FirstName=this.user.nome;
        a.LastName=this.user.cognome;
        a.MobilePhone=this.user.cellulare;
        a.PersonEmail=this.user.email;
        a.Email__c=this.user.email2;
        a.Sesso__c=this.user.sesso;
        a.PersonMailingStreet=this.user.residenza.indirizzoresidenza;
        a.PersonMailingCity=this.user.residenza.cittaresidenza;
        a.PersonMailingPostalCode=this.user.residenza.capresidenza;
        a.PersonMailingState=this.user.residenza.nazioneresidenza=='Italia'?this.user.residenza.provinciaresidenza:'EE - For Foreign Country';
        a.PersonMailingCountry=this.user.residenza.nazioneresidenza;
        a.Privacy_Consenso_Acquisito__pc = this.user.privacy;
        a.Privacy_Marketing__pc = this.user.marketing;
        a.Titolo_di_studio__c  = this.user.studio.titolostudio;
        a.CF__c = this.user.nascita.codicefiscale;
        var toUTC = new Date(this.user.nascita.datanascita).toUTCString();
        console.log(toUTC);
        a.Data_di_nascita__c = moment(toUTC).unix()*1000;
        a.Citta_di_nascita__c = this.user.nascita.cittanascita;
        a.Provincia_di_Nascita__c = this.user.nascita.provincianascita;
        a.Nazione_di_nascita__c = this.user.nascita.nazionenascita;
        a.Language__c = this.$i18n.locale;
        a.Status__c='Candidato';

        if (this.$store.state.userprofile.Id) a.Id=this.$store.state.userprofile.Id;
        else {
          a.AccountSource = 'Form Voucher';
        }
        a.Username__pc = this.user.email;
        a.Password__pc = this.user.password;
        var self=this;
        this.$store.dispatch('setCandidatoVoucher', { account: a}).then(function() {
          var data={ username: self.user.email, password: self.user.password };
          self.$store.dispatch('login', data).then(function() {
            var data2={ username: self.$store.state.connection.username, crypt: self.$store.state.connection.userdata };
            self.$store.dispatch('getProfile', data2).then(function() {
              console.log('profile recoveder');
              self.uploading = false;
              self.$emit('done');
            }).catch(function(err) {
                self.uploading = false;
                console.log('problemi:')
                console.log(err)
                console.log('problemi:')
                console.log(err.response.data)
                alert('Errori nel riscattare il voucher, contattare l\'assistenza!');
              });
          }).catch(function(err) {
              self.uploading = false;
              console.log('problemi:')
              console.log(err)
              console.log('problemi:')
              console.log(err.response.data)
              alert('Errori nel riscattare il voucher, contattare l\'assistenza!');
            });
        }).catch(function(err) {
            self.uploading = false;
            console.log('problemi:')
            console.log(err)
            console.log('problemi:')
            console.log(err.response.data)
            alert('Errori nel riscattare il voucher, contattare l\'assistenza!');
        });
    },
    getuserdata: function() {
        this.user.nome=this.$store.state.userprofile.FirstName;
        this.user.cognome=this.$store.state.userprofile.LastName;
        this.user.sesso=this.$store.state.userprofile.Sesso__c;
        this.user.telefono=this.$store.state.userprofile.Phone;
        this.user.cellulare=this.$store.state.userprofile.PersonMobilePhone;
        this.user.telefono2=this.$store.state.userprofile.Phone_2__c;
        this.user.email=this.$store.state.userprofile.PersonEmail;
        this.user.email2=this.$store.state.userprofile.Email__c;

        this.user.nascita.datanascita=this.$store.state.userprofile.Data_di_nascita__c;
        this.user.nascita.codicefiscale=this.$store.state.userprofile.CF__c ;
        this.user.nascita.cittanascita=this.$store.state.userprofile.Citta_di_nascita__c;
        this.user.nascita.provincianascita=this.$store.state.userprofile.Provincia_di_Nascita__c;
        this.user.nascita.nazionenascita=this.$store.state.userprofile.Nazione_di_nascita__c;

        this.user.residenza.indirizzoresidenza=this.$store.state.userprofile.PersonMailingStreet;
        this.user.residenza.cittaresidenza=this.$store.state.userprofile.PersonMailingCity;
        this.user.residenza.capresidenza=this.$store.state.userprofile.PersonMailingPostalCode;
        this.user.residenza.provinciaresidenza=this.$store.state.userprofile.PersonMailingState;
        this.user.residenza.nazioneresidenza=this.$store.state.userprofile.PersonMailingCountry;
        this.user.residenza.cittadinanza=this.$store.state.userprofile.Cittadinanza__c;
        this.user.residenza.cittadinanza2=this.$store.state.userprofile.Cittadinanza_2__c;

        this.user.studio.titolostudio=this.$store.state.userprofile.Titolo_di_studio__c;
        this.user.studio.annotitolo=this.$store.state.userprofile.Anno_diploma__c;
        this.user.studio.nomeistituto=this.$store.state.userprofile.Nome_Istituto__c;
        this.user.studio.cittaistituto=this.$store.state.userprofile.Citta_Istituto__c;
        this.user.studio.provinciaistituto=this.$store.state.userprofile.Provincia_Istituto__c;
        this.user.studio.nazioneistituto=this.$store.state.userprofile.Nazione_Istituto__c;
        this.user.studio.altrescuole=(this.$store.state.userprofile.isAltreScuole__c?'Si':'No');
        this.user.studio.altrascuolacucina=this.$store.state.userprofile.Altre_Scuole_Cucina__c;
        this.user.studio.conosciutoalma=this.$store.state.userprofile.ConosciutoALMA__c;

        this.user.fatturazione.ragionesociale=this.$store.state.userprofile.Ragione_Sociale__c;
        this.user.fatturazione.piva=this.$store.state.userprofile.PIVA__c;
        this.user.fatturazione.codicefiscale=this.$store.state.userprofile.Codice_Fiscale__c;
        this.user.fatturazione.pec=this.$store.state.userprofile.PEC__c;
        this.user.fatturazione.sdi=this.$store.state.userprofile.Codice_SDI__c;
        this.user.fatturazione.indirizzo=this.$store.state.userprofile.BillingStreet;
        this.user.fatturazione.citta=this.$store.state.userprofile.BillingCity;
        this.user.fatturazione.cap=this.$store.state.userprofile.BillingPostalCode;
        this.user.fatturazione.provincia=this.$store.state.userprofile.BillingState;
        this.user.fatturazione.paese=this.$store.state.userprofile.BillingCountry;

        this.user.username=this.$store.state.userprofile.Username__pc;
        this.user.password=this.$store.state.userprofile.Password__pc;

    }
  }
};
</script>

<style scoped>
</style>