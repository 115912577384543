<template>
    <div class="home">
        <navbar :menulist="$store.state.mainmenu" />
        <herobig :title="$t('title')" />

        <h2 class="welcome">{{$t('subtitleCommerce')}}</h2>

        <boxcontainer>
            <div class="row">
                <div
                    :class="['mx-auto col-lg-' + box.w]"
                    v-for="box in boxes" :key="box.id"
                >
                    <box
                        :imgname="box.imgname"
                        :alttext="$t('boxesCommerce' + box.id + '.alttext')"
                        :title  ="$t('boxesCommerce' + box.id + '.title')"
                        :menulist="box.menulist"
                        :item="box.item"
                        :extlink="box.extlink"
                        :disabled="box.disabled"
                    >
                    </box>
                </div>
            </div>
        </boxcontainer>

        <footerline />
    </div>
</template>

<script>
    // @ is an alias to /src
    import navbar       from '@/components/navbar.vue'
    import herobig      from '@/components/herobig.vue'
    import box          from '@/components/box.vue'
    import boxcontainer from '@/components/boxcontainer.vue'
    import footerline   from '@/components/footerline.vue'

    export default {
        name: 'EcommerceHome',
        components: {
            navbar,
            herobig,
            box,
            boxcontainer,
            footerline
        },
        data: function() {
            return {
                boxes: []
            }
        },
        mounted: function() {
            var w = 12 / 4

            this.boxes.splice(0, this.boxes.length)

            this.boxes.push({
                id: 1,
                w: w,
                imgname: 'img/regolamento.jpg',
                item: { title: 'Acquisti', path: 'EcommerceShop' },
                disabled: !this.$store.getters.isEcommerceVisible
            }, {
                id: 2,
                w: w,
                imgname: 'img/didattica.jpg',
                item: { title: 'Storico', path: 'EcommerceStorico' },
                disabled: !this.$store.getters.isEcommerceVisible
            }, {
                id: 3,
                w: w,
                imgname: 'img/finanziamentiesconti.jpg',
                item: { title: 'Ricarica Badge', path: 'EcommerceBadge' },
                disabled: !this.$store.getters.isRicaricaVisible
            })
        }
    }
</script>