<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <h2 class="welcome">{{$t('subtitleCandidatura')}}</h2>

    <boxcontainer>

 <formcandidatura></formcandidatura>
    </boxcontainer>
    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'
  import formcandidatura from '@/components/formcandidatura.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      boxcontainer,
      footerline,
      formcandidatura
    },
    data: function () {
      return {
      };
    },
    mounted: function() {
    }
  }
</script>